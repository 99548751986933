import { MultPreSer } from "@models/bookings/MultPre";
import { MultiSer } from "@models/bookings/multiser";
import { doRandomUid } from "../random/doRandomUid";
import { toMinutes } from "../dates/toMinutes";

export const preToMultCounter = (pre: MultPreSer[], centerId: string) => {
    const multCounter: MultiSer[] = [];
    pre.forEach((ob, i) => {
      const isExpo = ob.service.isExpo === true;
      const serPrice = parseFloat(ob.service.price)
      const am = ob.bono || ob.ticketUsed === true ? 0 : 
      isExpo ? (serPrice / ob.service.expoArr.filter(el => el.rest === false).length) : 
      serPrice;
      let it: MultiSer = {
        workerName: ob.workerName,
        serviceName: ob.service.serviceName,
        servicePrice: am,
        bono: ob.bono || ob.ticketUsed === true,
        numberSesSer: ob.service.numberSessions,
        apar: ob.service.apar,
        serviceUid: ob.service.serviceId,
        bonoId: ob.bonoId,
        multCounterId: doRandomUid(centerId),
        serDur: ob.service.duration,
        isExpo: isExpo,
        restTempo: 0
      }
      if (isExpo) {
        const arr = ob.service.expoArr;
        arr.forEach((ex, x) => {
          if (ex.rest === false) {
            it = {...it, 
              serDur: ex.dur,
              multCounterId: doRandomUid(centerId),
              restTempo: arr[x + 1] !== undefined ? toMinutes(arr[x + 1].dur) : 0,
              bono: x === 0 && it.bono ? true : false,
              bonoId: x === 0 && it.bono ? ob.bonoId : '',
            }
            multCounter.push(it);
          }  
        })
      } else {
        multCounter.push(it);
      }
    })
    return multCounter; 
}