import {  Col, Row } from '@assets/sass/bootstrapEx'
import { SelectField } from '@components/formik/SelectField'
import Switch from '@components/formik/Switch'
import { RadioArray } from '@components/formik/radio/RadioArray'
import { ActionButton } from '@components/ui/button/ActionButton'
import useCats from '@lib/hooks/worker/useCats'
import useServicesForWorker from '@lib/hooks/worker/useServicesForWorker'
import { Services } from '@models/services/services'
import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
interface Props {
    name: string,
    services: Services[]
}
export const WorkerSer = ({ name, services }: Props) => {
    const { catOpt } = useCats()
    const { values, setFieldValue } = useFormikContext<any>();
    const { serOpt } = useServicesForWorker(values.catName, services)
    useEffect(() => {
        if (values.selectAll) {
            setFieldValue(name, [...serOpt.map((value) => value.value)])
        } else {
            setFieldValue(name, [])
        }
    }, [values.selectAll, setFieldValue, serOpt, name])
    

    return (
        <div>
            <div className='d-flex justify-content-lg-between flex-lg-row  flex-column'>
                <div className='d-flex justify-content-center flex-column'>
                    <div className={`${Row} align-items-center`}>
                        <Switch nameField={'selectAll'} holder={'Selecciona todo'} defvalue={false} />
                    </div>
                </div>
                <div className={`${Col} `}>
                    <SelectField clearable={true} search={true} opts={catOpt} name={'catName'} placeHolder={'Busca por categoría'} />
                    {
                        values.catName !== '' ?
                        <ActionButton classNames='p-2 mt-2' text='Volver a todos' action={() => setFieldValue('catName', '')} />
                        :
                        null
                    }
                </div>
            </div>
            <div className=' container'>
                <div className='row row-cols-2'>
                    {
                        serOpt.map((value, index) => (
                                <RadioArray
                                    classNames='w-300px col mt-3 mb-3'
                                    key={`${value.id}-${index}`}
                                    name={'checkArray'}
                                    value={value.value}
                                    holder={value.label}
                                />
                        ))
                    }
                </div>
            </div>
        </div>

    )
}
