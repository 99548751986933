import { useState } from 'react'
import { db } from "@lib/firebase";
import { collection, doc, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Messages } from '@models/user/messages';
import { BoldText } from '@components/ui/text/BoldText';
import { NorCard } from '@components/ui/cards/normal/NorCard';
export const SeeAns = () => {
    const { id } = useParams();
    const [quests, setquests] = useState<Messages[]>([])
    useEffect(() => {
        const userRef = doc(db, `users/${id}`);
        const subcollectionRef = query(collection(userRef, 'messages'), orderBy('creation', 'desc'))
        const sub = onSnapshot(subcollectionRef, (snap) => {
            const docs = snap.docs.map((v) => {
                const data = v.data() as Messages;
                data.docId = v.id;
                return data;
            })
            setquests(docs)
        })
        return () => {
            sub()
        }
    }, [id])
    return (
        <>
            {
                id ?
                <div>
                    {
                        quests.length === 0 ?
                        <BoldText classNames='text-center fs-2' text='No ha respondido a preguntas' /> : null
                    }
                    {
                        quests.map((v) => (
                            <NorCard key={v.docId} className='mb-3' mess={v} id={id} />
                        ))
                    }
                </div>
                : null
            }        
        </>
    )
}
