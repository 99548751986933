import { SearchAlgo } from './SearchAlgo';
import { QueryFieldFilterConstraint } from 'firebase/firestore';
import useTableQuery from '@lib/hooks/table/useTableQuery';
import { KTCard } from '_metronic/helpers';
import { TableBody } from '../TableBody';
import TabUsers from '@components/clients/page/TabUsers';
import HardPage from './HardPage';
import ChooseTab from './ChooseTab';
import { FilterDate } from './FilterDate';
/* 
  
  dev the rest of the table:
    
  --------
  handle all the cols with its index, save them on estetical folder
  you needto all atts of the table on hit
  create another account
  pass strings as env variables
  make sure they are on CI/CD
 
*/

type TabName = 'users'
type Props = {
  needsSearch: boolean,
  nameCol: string,
  queryCons: QueryFieldFilterConstraint[],
  nameOrder: string,
  heads: string[],
  filterDate?: true,
  other?: React.ReactNode,
  desc?: boolean
}


const TableCnt = ({ other, heads, needsSearch, nameCol, queryCons, nameOrder, desc, filterDate }: Props) => {
  const { changeStart, getSearchResult, dataTable, countDocs, page, searchRes } = useTableQuery(nameCol, queryCons, nameOrder, desc);
  console.log(searchRes)
  return (
    <>
      <KTCard>
        <TableBody heads={heads} options={
          <>
              <div className='d-flex mb-3 justify-content-between align-items-center'>
                {
                  filterDate ?
                    <FilterDate sendResBack={getSearchResult} nameCol={nameCol} />
                    : null
                }
              </div>
            <div className='d-flex justify-content-between flex-lg-row flex-column align-items-center'>
              {
                needsSearch ?
                <SearchAlgo sendResBack={getSearchResult} nameCol={nameCol} />
                : null
              }
              {other}
            </div>
          </>
        }>
          {
            searchRes.length === 0 ?
              <ChooseTab nameCol={nameCol} dataTable={dataTable} />
              :
              <ChooseTab nameCol={nameCol} dataTable={searchRes} />
          }
        </TableBody>
        {
          searchRes.length > 0 ?
            null
            :
            <HardPage className={'pagination-bar'} onChange={changeStart} totalResults={countDocs} currentPage={page} />
        }
      </KTCard>
    </>
  )
}

export default TableCnt