import { SelectField } from '@components/formik/SelectField';
import { db } from '@lib/firebase';
import { Option } from '@models/opts/Option';
import { UserFinal } from '@models/user/userFinal';
import { useAuth } from 'app/modules/auth'
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react'
interface Props {
  clearable?: boolean,
  label?: string
} 
export const SearchUserFast = ({ clearable, label }: Props) => {
  const { centerId } = useAuth();
  const [opts, setopts] = useState<Option[]>([])
  useEffect(() => {
    async function getClients() {
      const allUsers = (await getDocs(query(collection(db, 'users'), where('centerClient', '==', centerId)))).docs.map((value => {
        const data = value.data();
        data.uid = value.id;
        return data
      })) as UserFinal[];
      const tr = allUsers.map((value) => ({ label: `${value.displayName} ${value.phone}`, value: value.uid }))
      setopts(tr);
    }
    getClients()
  }, [centerId])

  

  return (
      <SelectField clearable={clearable} opts={opts} search={true} name={'client'} placeHolder={label ? label : ''} />
  )
}
