import {selectServices } from "@redux/features/gquerySlice/gquerySlice";
import { useAppSelector } from "@redux/store/hooks";
import { useMemo } from "react";
import { Option } from "@models/opts/Option";
const useServicesBooking = (
    onlyServices: string[]
) => {
    const services = useAppSelector(selectServices);
    const optsServices: Option[] = useMemo(() => {
        const opts = services.map((value) => ({ label: value.serviceName, value: value.serviceId }))
        if (onlyServices.length === 0) {
            return opts
        } else {
            return opts.filter(el => onlyServices.includes(el.value))
        }
    }, [services, onlyServices]);
    return {
        optsServices,
        services
    }
}

export default useServicesBooking