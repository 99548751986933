import { useState, useEffect } from 'react';
import { Option } from '@models/opts/Option'

export default function useHoursSer() {
    const [optsDispo, setoptsDispo] = useState<Option[]>([]);
    useEffect(() => {
        const x = 5;
        let tt = 0;
        const hs: string[] = []
        for (let i = 0; tt <= 12 * 60; i++) {
            const hh = Math.floor(tt / 60);
            const mm = (tt % 60);
            const time = ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
            tt = tt + x;
            hs.push(time)
        }
        hs.shift();
        setoptsDispo(hs.map((value) => ({ label: value, value: value })))
    }, [])

    return { optsDispo };
}
