import { BoldText } from '@components/ui/text/BoldText'
import classnames from 'classnames';
import '@assets/sass/customPag.scss'
import React, { useEffect, useState, useMemo } from 'react'
type Props = {
  totalResults: number,
  currentPage: number,
  onChange: (activePage: number) => void;
  className: string
}
const HardPage = ({ totalResults, currentPage, onChange, className }: Props) => {
  const [allPages, setallPages] = useState<number[][]>([])
  const [lastPage, setlastPage] = useState(0)
  useEffect(() => {
    if (totalResults > 0) {
      const l = totalResults / 10;
      setlastPage(Math.ceil(l));
    }
  }, [totalResults])
  useEffect(() => {
    if (lastPage > 0) {
      const l = Math.ceil(lastPage / 5);
      const resultArrays = [];
      for (let index = 0; index < l; index++) {
        const start = index * 5 + 1;
        const end = Math.min((index + 1) * 5, lastPage);
        const pageArray = [];
        for (let i = start; i <= end; i++) {

          pageArray.push(i);
        }
        resultArrays.push(pageArray);
      }
      setallPages(resultArrays)
    }
  }, [lastPage])


  const currSet = useMemo(() => {
    if (currentPage === 1 && allPages.length > 0) {
      return allPages[0]
    } else {
      const filteredArrays = allPages.filter((subArray, i) => {
        return subArray.some(num => num === currentPage)
      })[0];
      return filteredArrays ? filteredArrays : []
    }
  }, [currentPage, allPages,])

  const onSel = (active: number) => {
    onChange(active)
  }
  const onNext = () => {
    onChange(currentPage + 1);
  };

  const onPrevious = () => {
    onChange(currentPage - 1);
  };
  return (
    <div className='d-flex flex-column'>
      <div style={{ paddingLeft: '2rem' }}>
        <BoldText text={`Total: ${totalResults}`} />
      </div>
      {
        totalResults > 0 ?
          <div className='d-flex flex-row align-items-center'>
            <ul
              className={classnames('pagination_est', { [className]: className })}

            >
              <li
                className={classnames('pagination-item', {
                  disabled: currentPage === 1
                })}
                onClick={onPrevious}
              >
                <div className="arrow left" />
              </li>
              {currSet.length > 0 ?
                currSet.map((v) => (

                  <li
                    key={`${v}`}
                    onClick={() => onSel(v)}
                    className={classnames('pagination-item', {
                      selected: v === currentPage ? true : false,
                    })}
                  >
                    {v}
                  </li>
                ))
                : null
              }
              <li
                className={classnames('pagination-item', {
                  disabled: currentPage === lastPage
                })}
                onClick={onNext}
              >
                <div className="arrow right" />
              </li>
            </ul>
            <div style={{ paddingLeft: '2rem' }}>
              <BoldText text={`Página ${currentPage} de ${lastPage}`} />
            </div>
          </div>
          : null
      }
    </div>
  )
}

export default HardPage