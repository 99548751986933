import { db } from "@lib/firebase";
import { SimpleSell } from "@models/sells/sell"
import { getDoc, deleteDoc, doc } from "firebase/firestore";

export const delSellsBonusBills = async (simpleSell: SimpleSell) => {
    if (simpleSell.type === 'BONUS') {
        const thereisSell = (await getDoc(doc(db, `sellsBonus/${simpleSell.parentId}`))).exists();
        if (thereisSell){
          await deleteDoc(doc(db, `sellsBonus/${simpleSell.parentId}`))
        }     
    }
}