import { db } from "@lib/firebase";
import { SellsBonus } from "@models/bono/sellsBonus";
import { Services } from "@models/services/services";
import { Ticket } from "@models/ticket/ticket";
import { selectCounter } from "@redux/features/calSlice/calSlice";
import { useAppSelector } from "@redux/store/hooks";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react"

const useBonosBooking = (userUid: string, servicesByWorker: Services[]) => {
    const [bonoUser, setbonoUser] = useState<SellsBonus[]>([]);
    const [ticketsUser, setticketsUser] = useState<Ticket[]>([]);
    const counter = useAppSelector(selectCounter);
    const bonosAsOps = useMemo(() => {
        if (bonoUser.length > 0) {

            let clean = bonoUser.filter(b => {
                const tk = ticketsUser.find(t => t.code === b.name);
                return !tk || tk.servicesUsed.length !== tk.servicesInc.length;
            });
            if (servicesByWorker.length > 0) {
                clean = clean.filter((bono) =>
                    servicesByWorker.some((s) => bono.services.some((a) => a.id === s.serviceId))
                )
            }
            return clean.map((value) => ({ label: value.name, value: value.id as string }))
        } else {
            return []
        }
    }, [bonoUser, ticketsUser, servicesByWorker])
    useEffect(() => {
        let active = true;
        if ((!userUid || userUid === '') || !active) {
            return
        }
        async function getBonos() {
            const qBono = query(collection(db, 'sellsBonus'),
                where('userUid', '==', userUid),
                where('firstPayment', '==', true));
            const bonos = (await getDocs(qBono)).docs.map((doc) => {
                const data = doc.data() as SellsBonus;
                data.id = doc.id
                return data;
            }).map(({ dateTime, ...rest }) => rest) as SellsBonus[];
            const cleanBonos = bonos.filter(sell => {
                if (sell.servicesLimit === undefined) {
                    return (sell.numberSessions as number) > 0;
                } else {
                    return sell.servicesLimit.find(el => el.sesNumber > 0);
                }
            });
            setbonoUser(cleanBonos);
        }
        getBonos()
        return () => {
            active = false;
        }
    }, [userUid, counter])

    useEffect(() => {
        let active = true;
        if ((!userUid || userUid === '') || !active) {
            return
        }
        async function getTickets() {
            const qTicket = query(collection(db, 'tickets'),
                where('userReceives', '==', userUid));
            const tickets = (await (getDocs(qTicket))).docs.map((value) => {
                const data = value.data() as Ticket;
                data.uid = value.id;
                return data
            }) as Ticket[];
            setticketsUser(tickets);
        }
        getTickets()
        return () => {
            active = false
        }

    }, [userUid, counter])

    return {
        bonoUser,
        ticketsUser,
        bonosAsOps,
        setbonoUser,
        setticketsUser
    }

}

export default useBonosBooking