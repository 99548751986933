import { SimpleSell } from '@models/sells/sell'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '@redux/store/hooks'
import { Form } from '@components/formik/Form'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { CrossSell } from '@models/sells/crossSell'
import { tarIcon, efIcon, CenterCol } from '@assets/sass/bootstrapEx'
import { RadioField } from '@components/formik/radio/RadioField'
import { RadioBoxContainer } from '@components/formik/radio/box/RadioBoxContainer'
import { BoldText } from '@components/ui/text/BoldText'
import { Spinner } from '@components/ui/spinner/Spinner'
import { SubmitField } from '@components/formik/SubmitField'
import { closeSellsModal } from '@redux/features/billSlice/billSlice'
type Props = {
    item: SimpleSell,
    newPrice: string
}
const initialValues = { paymentMethod: '' }
const schema = Yup.object().shape({ paymentMethod: Yup.string().required('Es necesario uno de los métodos') })
export const EditDiffCross = ({ item, newPrice }: Props) => {
    const dispatch = useAppDispatch()
    const [mess, setmess] = useState('')
    const [crossSell, setcrossSell] = useState(null as CrossSell | null)
    const [loading, setloading] = useState<boolean>(false)
    useEffect(() => {
        const n = Number(newPrice);
        const isUp = item.amount < n
        const diff = isUp ? n - item.amount : item.amount - n;
        if (n > item.amount) {
            setmess(`El precio de ${item.name} ha cambiado al alza en ${diff} €. ¿De dónde hay que sumar los ${diff} €?`)
            return
        } else {
            setmess(`El precio de ${item.name} ha cambiado a la baja en ${diff} €. ¿De dónde hay que restar los ${diff} €?`)
            return
        }
    }, [item, newPrice])

    useEffect(() => {
        let active = true;
        if (active) {
            const setCross = async () => {
                setloading(true)
                const cross = (await getDoc(doc(db, `sells/${item.parentId}`))).data() as CrossSell;
                setcrossSell(cross)
                setloading(false)
            }
            setCross()
        }
        return () => {
            active = false;
        }
    }, [item])


    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            setloading(true)
            const n = Number(newPrice);
            const isUp = item.amount < n
            const diff = isUp ? n - item.amount : (item.amount - n) * -1;
            const newArr = crossSell!.sells.map((sell) =>
                ({ ...sell, amount: sell.uidFind === item.uidFind ? Number(newPrice) : sell.amount }))
            const efQnt = values.paymentMethod === 'Efectivo' ? (crossSell!.efQnt) + diff : crossSell!.efQnt;
            const tarQnt = values.paymentMethod === 'Tarjeta de crédito' ? (crossSell!.tarQnt) + diff : crossSell!.tarQnt
            const newAmount = efQnt + tarQnt;
            await updateDoc(doc(db, `sells/${item.parentId}`), {
                sells: newArr,
                efQnt: efQnt,
                tarQnt: tarQnt,
                amount: newAmount
            })
            setloading(false)
            dispatch(closeSellsModal())
        },
    })
    return (
        <>
            {
                loading ? <Spinner />
                    :
                    <Form formikVal={formik}>
                        <BoldText classNames='text-center mt-5' text={`El total de tarjeta es ${crossSell?.tarQnt.toFixed(2)}, el total en efectivo es de ${crossSell?.efQnt.toFixed(2)}`} />
                        <BoldText classNames='text-center mt-5' text={`${mess}`} />
                        <div className='mb-5 col-lg-8 fv-row m-auto '>
                            <RadioBoxContainer >
                                <RadioField icon={tarIcon} name={'paymentMethod'} value={'Tarjeta de crédito'} title={`Tarjeta de crédito `} />
                                <RadioField icon={efIcon} name={'paymentMethod'} value={'Efectivo'} title={`Efectivo `} />
                            </RadioBoxContainer>
                        </div>
                        <div className={`${CenterCol} mt-5`}>
                            <SubmitField name={'paymentMethod'} loading={false} handleSubmit={() => formik.submitForm()} />
                        </div>
                    </Form>

            }
        </>
    )
}
