import { BarItem } from '@components/ui/item/BarItem'
import { BoldText } from '@components/ui/text/BoldText'
import { selectorEditId } from '@redux/features/uiSlice/uiSlice'
import { useAppSelector } from '@redux/store/hooks'
import { useEffect, useState } from 'react'

export const ShowBonoSer = () => {
    /* 
        no need to change since will map names from TableBono
    */
    const id = useAppSelector(selectorEditId)
    const [sers, setsers] = useState<string[]>([])
    useEffect(() => {
        if (id !== '') {
            const arr = id.split(',');
            setsers(arr)
        } else {
            setsers([])
        }
    }, [id])

  if (sers.length === 0) {
    
    return <div className='d-flex flex-wrap justify-content-center mt-5 p-4'>
        <BoldText classNames='text-center' text='No hay ningún servicio' />
    </div>
  }
  return (
    <div className='d-flex flex-wrap justify-content-center mt-5 p-4'>

        {
            sers.map((v, index) => (
                <div key={index} className='m-4'>
                    <BarItem  title={v} desc={''} back={''}  />
                </div>
            ))
        }
    </div>
  )
}
