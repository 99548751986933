import { BonoForm } from '@components/common/wiz/wiztypes/CreateBonoWiz'
import { InlineInput } from '@components/formik/InlineInput'
import { InlineSelect } from '@components/formik/InlineSelect'
import { Badge } from '@components/ui/badge/Badge'
import { ActionButton } from '@components/ui/button/ActionButton'
import { SimpleCard } from '@components/ui/item/SimpleCard'
import useServicesBooking from '@lib/hooks/calendar/book/useServicesBooking'
import { SessionsPack, serviceLimit } from '@models/bono/sessionsPack'
import { useFormikContext } from 'formik'
import toast from 'react-hot-toast'

export const Pers = () => {
  const { optsServices, services } = useServicesBooking([])
  const { setFieldValue, values, } = useFormikContext<BonoForm>();
  const add = () => {
    const form = values;
    const sel = services.filter(s => s.serviceId === form.currService)[0];
    if ((form.servicesLimit as serviceLimit[]).some(el => el.id === sel.serviceId)) {
      toast.error('Servicio ya incluído')
    } else {
      const serLimit: serviceLimit = {
        sesNumber: Number(form.limitPack),
        name: sel.serviceName,
        id: sel.serviceId
      }
      let currLimit = [...form.servicesLimit as serviceLimit[], serLimit];
      setFieldValue('servicesLimit', currLimit)
    }
    setFieldValue('currService', '')
  }
  const addPack = () => {
    const form = values;
    const newPack: SessionsPack = {
      name: form.name,
      amountPack: form.amountPack,
    }
    newPack.servicesLimit = form.servicesLimit
    let curr = [...form.sessionsPack as SessionsPack[], newPack];
    setFieldValue('sessionsPack', curr)
    resetPack()
  }
  const resetPack = () => {
    setFieldValue('name', '')
    setFieldValue('amountPack', '')
    setFieldValue('limitPack', '')
    setFieldValue('servicesLimit', [])
  }
  const deletePack = (index: number) => {
    const form = values;
    let curr = form.sessionsPack as SessionsPack[];
    curr = curr.filter((_, i) => i !== index);
    setFieldValue('sessionsPack', curr)
}
  const alwaysInPack = values.name !== '' && values.amountPack !== '' && values.servicesLimit.length > 0;

  return (
    <div>
      <InlineInput name={`name`} label={'Nombra el pack'} />
      <InlineInput name={`amountPack`} label={'Pon precio al pack'} />
      <div className='d-flex justify-content-start'>
        {
          values.servicesLimit.map((value: serviceLimit) => (
            <div key={value.id} className='position-relative'>
              <Badge text={`${value.name}(${value.sesNumber})`} type={'SUCC'} />
            </div>
          ))
        }
      </div>
      <InlineSelect name='currService' opts={optsServices} search={true} label={'Elige un servicio'} />
      <InlineInput name={`limitPack`} label={'Pon el número de sesiones al servicio'} />
      <div className='d-flex justify-content-between align-items-center'>

        {
          values.currService !== '' && Number(values.limitPack) > 0 &&
          <ActionButton text={'Añadir servicio'} action={add} classNames='mt-3 mb-3' />
        }
        {
          alwaysInPack ?
            <ActionButton text={'Añadir pack'} action={addPack} /> : null
        }
      </div>
      <div className='d-flex'>
        {
          values.sessionsPack.map((values: SessionsPack, index) => (
            <div key={index} className='position-relative w-300px me-3'>
              <i className="fa-solid fa-x position-absolute top-0 end-0 is-pointer text-danger " style={{ zIndex: 3 }} onClick={() => deletePack(index)}></i>
              <SimpleCard
                childIcon={
                  <i className={`${values.servicesLimit ? 'fa fa-paint-brush' : 'fa-solid fa-gift'} text-white`}
                    style={{ fontSize: 35 }}></i>
                }
                classNames='w-300px h-auto mt-5'
                title={values.name}
                text1={values.servicesLimit ?
                  `${values.servicesLimit.map((v) => `${v.name}(${v.sesNumber})`).join(', ')}`
                  : `Sesiones a gastar: ${values.sessionPack}`}
                  text2={`Precio ${values.amountPack} €`}
                back={values.servicesLimit ? 'bg-success' : 'bg-info'} />
            </div>
          ))
        }
      </div>

    </div>
  )
}
