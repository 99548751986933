import { Col } from '@assets/sass/bootstrapEx';
import { Acc } from '@components/ui/accordion/Acc';
import { selectUser } from '@redux/features/clientSlice/clientSlice'
import { useAppSelector } from '@redux/store/hooks'
import React, { useEffect, useState } from 'react'
import { FakeEmail } from './FakeEmail';
import { ChangeEmail } from './ChangeEmail';
import { ChangePhone } from './ChangePhone';
import { ChangeName } from './ChangeName';
import { UserBirth } from './UserBirth';
import { UserFinal } from '@models/user/userFinal';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '@lib/firebase';
import { format } from 'date-fns';
import { useAuth } from 'app/modules/auth';
import { TrackWhats } from './TrackWhats';
import { SeeWhats } from './SeeWhats';

export const EditUser = () => {
  const user = useAppSelector(selectUser);
  const [userChanges, setuser] = useState<UserFinal | null>(null);
  const { currentUser } = useAuth();
  console.log(userChanges, user)
  useEffect(() => {
    const docUser = doc(db, `users/${user?.uid}`)
    const sub = onSnapshot(docUser, (snap) => {
      const rawData = snap.data();
      if (rawData && rawData.birthDate) {
        rawData.birthDate = rawData.birthDate.toDate()
        rawData.birthDate = format(rawData.birthDate, 'dd-MM-yyyy')
      }
      setuser(rawData as UserFinal)
    })
    return () => {
      sub()
    }
  }, [user])
  return (
    <div className={`${Col} p-3`}>
      {
        userChanges !== null ?
        <>
          <Acc classNames='mt-3' title={userChanges?.fakeEmail ? 'El email es inventado'  : 'El email es verdadero'} id={'FakeEmail'}>
            <FakeEmail />
          </Acc>
          <Acc classNames='mt-3' title={`Email actual: ${userChanges?.email}`} id={'Email'}>
            <ChangeEmail />
          </Acc>
          <Acc classNames='mt-3' title={`Teléfono actual: ${userChanges?.phone}`} id={'Phone'}>
            <ChangePhone />
          </Acc>
          <Acc classNames='mt-3' title={`Nombre actual: ${userChanges?.displayName}`} id={'Name'}>
            <ChangeName />
          </Acc>
          <Acc classNames='mt-3' title={`Fecha de nacimiento: ${(userChanges as UserFinal).birthDate ? (userChanges as UserFinal).birthDate : 'No tiene'}`} id={'birth'}>
            <UserBirth />
          </Acc>
          {
            currentUser && currentUser.role.includes('superAd') ?
            <>
              <Acc classNames='mt-3' title='Whatsapp confirmación'  id={'Track'}>
                <TrackWhats />
              </Acc>

              <Acc classNames='mt-3' title='Ver últimos 10 whatsapp'  id={'SeeWhats'}>
                <SeeWhats />
              </Acc>
            </>
            : null
          }
        </>
        : null
      }
    </div>
  )
}
