import { Datepicker, localeEs } from '@mobiscroll/react'
import '@assets/sass/calendar.scss'
import { useAppSelector } from '@redux/store/hooks'
import { selectCenter, selectPureWorkers, } from '@redux/features/gquerySlice/gquerySlice'
import { useFormikContext } from 'formik'
import { BigBook } from '@components/common/wiz/wiztypes/BigBookWizHelper'
import useTurnsWorkersDisabled from '@lib/hooks/calendar/book/useTurnsWorkers'
import { selectTurnsAuto } from '@redux/features/calSlice/calSlice'
import useCreateTimes from '@lib/hooks/calendar/book/useCreateTimes'
import { useEffect, useState } from 'react'
import { CenterCol, PointerPillow, ResRowToCol, } from '@assets/sass/bootstrapEx'
import Switch from '@components/formik/Switch'
import { SelectField } from '@components/formik/SelectField'
import toast from 'react-hot-toast'
import { Spinner } from '@components/ui/spinner/Spinner'
import { MultShow } from './MultShow'
import { ActionButton } from '@components/ui/button/ActionButton'
import { InfoItem } from '@components/ui/item/InfoItem'
import { TimeV2 } from '@models/bookings/timeV2'
import { format } from 'date-fns'
import { TotalMult } from './TotalMult'
import { isInRec } from '@lib/helpers/dates/isInRec'
import usePickHour from '@lib/hooks/calendar/pickHour/usePickHour'

export const PickDate = () => {
    const center = useAppSelector(selectCenter);
    const workers = useAppSelector(selectPureWorkers);
    const turnsAuto = useAppSelector(selectTurnsAuto);
    const { values, setFieldValue } = useFormikContext<BigBook>();
    const { festives, enabledDates, recs } = useTurnsWorkersDisabled(turnsAuto, workers, values, center);
    const {optsDispo} = usePickHour()
    const { calChange, times, loading, settimes } = useCreateTimes(turnsAuto, workers, values);
    const [totalAmount, settotalAmount] = useState<number>(0)

    const setTime = (time: TimeV2) => {
        settotalAmount(values.multArr.filter(el => el.bono === false && el.ticketUsed === false).reduce((a, b) => +a + +parseFloat(b.service.price), 0))
        setFieldValue('time', time);
    }

    const back = () => {
        setFieldValue('time', null)
    }

    useEffect(() => {
        settimes([]);
        setFieldValue('time', null)
    }, [values.multArr, setFieldValue, settimes])


    return (
        <div className='w-100'>
            {
                values.time === null ?
                    <div>
                        <TotalMult multArr={values.multArr} />
                        <MultShow actions={false} />
                        {/* <ShowBooks multArr={values.multArr} dateParse={values.d} /> */}
                        <div className={`${CenterCol}`}>
                            {
                                values.multArr[0] && values.multArr[0].service.doubleWorker === false &&
                                <Switch nameField={'wantsHour'} holder={'¿Quieres escoger una hora?'} defvalue={false} />
                            }
                            {
                                values.wantsHour ?
                                    <div className='mb-2 mb-2'>
                                        <SelectField search={false} opts={optsDispo} name={'dispoHour'} placeHolder={''} />
                                    </div>
                                    : null
                            }
                        </div>
                        <Datepicker
                            controls={['calendar']}
                            display="inline"
                            touchUi={true}
                            locale={localeEs}
                            theme='ios'
                            min={new Date()}
                            onCellClick={(props) => {
                                if (values.wantsHour) {
                                    if (values.dispoHour === '') {
                                        return toast.error('Selecciona una hora primero')
                                    }
                                }
                                const d = props.date as Date;
                                if (!isInRec(d.getDay(), recs)) {
                                    setFieldValue('d', format(props.date, "d/M/yyyy, HH:mm"))
                                    calChange(props.date)
                                } else {
                                    settimes([])
                                    return;
                                }
                            }}
                            defaultSelection={null}
                            invalid={festives}
                            valid={enabledDates}
                            colors={recs}
                        />
                        {
                            loading ?
                                <div className={`${CenterCol}`}>
                                    <Spinner />
                                </div>
                                : null
                        }

                        <div className='d-flex flex-wrap justify-content-center w-100'>
                            {
                                times.map((value, index) => (
                                    <div onClick={() => { setTime(value) }} className={`${PointerPillow}`} style={{ width: 105, height: 40 }} key={index}>
                                        {value.hour}
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                    : <div className={`${CenterCol}`}>
                        <div className={`${ResRowToCol} justify-content-lg-end w-100`}>
                            <InfoItem back='bg-info' text={values.d.split(',')[0]} icon={'fa-solid fa-calendar'} />
                            <InfoItem back='bg-info' text={values.time.hour} icon={'fa-solid fa-clock'} />
                        </div>
                        <div className={`${ResRowToCol} justify-content-lg-end w-100`}>
                            <InfoItem back='bg-info' text={values.time.worker.replaceAll(',', ', ')} icon={'fa-solid fa-user-cog'} />
                            <InfoItem back='bg-info' text={`${totalAmount.toFixed(2)} €`} icon={'fa-solid fa-coins'} />
                        </div>
                        <div className='w-100 mt-5 mb-5' style={{maxWidth: 400}}>
                            <MultShow actions={false} />
                        </div>
                        <ActionButton text={'Volver al calendario'} action={back} />
                    </div>
            }
        </div>
    )
}
