import { BigBook } from "@components/common/wiz/wiztypes/BigBookWizHelper";
import { FastBook } from "@components/common/wiz/wiztypes/FastBookWizHelper";
import { db } from "@lib/firebase";
import { trToWasteBono } from "@lib/helpers/bonos/trToWasteBono";
import { addDoubleBook } from "@lib/helpers/booking/addDoubleBook";
import { addMultipleBook } from "@lib/helpers/booking/addMultipleBook";
import { addSimpleBook } from "@lib/helpers/booking/addSimpleBook";
import { momentEndFast } from "@lib/helpers/booking/expo/momentEndFast";
import { notify } from "@lib/helpers/booking/notify";
import { preToMultCounter } from "@lib/helpers/booking/preToMultCounter";
import { commitBatch } from "@lib/helpers/common/commitBatch";
import { retShowing } from "@lib/helpers/dates/retShowing";
import { toMinutes } from "@lib/helpers/dates/toMinutes";
import { doRandomUid } from "@lib/helpers/random/doRandomUid";
import { createRemember } from "@lib/helpers/remembers/createRemember";
import { MultPreSer } from "@models/bookings/MultPre";
import { Booking, DatesBooking } from "@models/bookings/booking";
import { TimeV2 } from "@models/bookings/timeV2";
import { UserFinal } from "@models/user/userFinal";
import { useAuth } from "app/modules/auth";
import { addMinutes, format, parse } from "date-fns";
import { WriteBatch, arrayUnion, collection, doc, getDoc, getDocs, query, where, writeBatch } from "firebase/firestore";
import toast from "react-hot-toast";


const useCreateBooking = () => {
    const { canary, currentUser } = useAuth();
    /* 
        works similar but having in count you have an array
    */
    const createFromBig = async (values: BigBook) => {
        const time = values.time as TimeV2;
        const workerNames = time.worker.split(',')
        const multsWithWorker = values.multArr.map((value, index) => ({ ...value, workerName: value.service.doubleWorker === true ? time.worker : workerNames[index] }))
        const start = parse(values.d, 'd/M/yyyy, HH:mm', new Date());
        start.setHours(0, 0, 0, 0)
        const datesBook: DatesBooking = {
            EndTime: addMinutes(start, time.end),
            StartTime: addMinutes(start, time.str),
            created: format(new Date(), 'dd-MM-yyyy HH:mm'),
            showingDate: retShowing(start),
            showingHour: time.hour.split('-')[0].trim(),
            momentIntervalIni: time.str,
            momentIntervalEnd:  time.end,
            isFirstTime: values.isFirstTime
        }
        await createBooking(datesBook, multsWithWorker, values.centerRest, canary, values.client)
    }
    const createFromFast = async (values: FastBook) => {
        const centerRest = values.centerRest
        const start = parse(values.d, 'd/M/yyyy, HH:mm', new Date());
        start.setHours(0, 0, 0, 0)
        const multsWithWorker = values.multArr.map((value) => ({ ...value, workerName: values.workerName })) as MultPreSer[];
        const minutesHourStart = toMinutes(values.d.split(',')[1])
        const end = momentEndFast(multsWithWorker, minutesHourStart, centerRest);
        const datesBook: DatesBooking = {
            EndTime: addMinutes(start, end),
            StartTime: addMinutes(start, minutesHourStart),
            created: format(new Date(), 'dd-MM-yyyy HH:mm'),
            showingDate: retShowing(start),
            showingHour: values.d.split(',')[1],
            momentIntervalIni: minutesHourStart,
            momentIntervalEnd: end,
            isFirstTime: values.isFirstTime
        }
        await createBooking(datesBook, multsWithWorker, centerRest, canary, values.client)
    }

    /* 
        Diff between fast booking and the other is how do we create the date
    */
    const createBooking = async (
        datesBooking: DatesBooking,
        preBooks: MultPreSer[],
        centerRest: number,
        addCanary: boolean,
        uid: string,
    ) => {
        const userDoc = (await getDoc(doc(db, `users/${uid}`))).data() as UserFinal
        const batch = writeBatch(db);
        const booking = retBookObSimple(datesBooking, preBooks, userDoc);
        if (booking.multiSer) {
          try {
            booking.multCounter = preToMultCounter(preBooks, currentUser?.centerId as string);
            addMultipleBook(booking, centerRest, addCanary, batch)
          } catch (error) {
            console.log(error)
            return toast.error('Hay un error al crear la cita')            
          }
        } else if (booking.doubleWorker) {
            booking.objDoubleWorker.workerIdA = preBooks[0].workerName.split(',')[0]
            booking.objDoubleWorker.workerIdB = preBooks[0].workerName.split(',')[1]
            addDoubleBook(booking, addCanary, batch)
        } else {
            addSimpleBook(booking, addCanary, batch)
        }
        await createRemember(booking, batch)
        await wasteBonoOrTicket(preBooks, batch) 
        const commit = await commitBatch(batch);
        if (!commit) {
            return toast.error('Hay un error al crear la cita')
        }
        toast.success('Cita creada correctamente')
        await notify(booking)
    }

    const retBookObSimple = (
        datesBooking: DatesBooking,
        preBooks: MultPreSer[],
        userDoc: UserFinal
    ) => {
        const preBook = preBooks[0];
        const isNotFirstCase = preBook.service.expoArr !== undefined && preBook.service.expoArr.length > 2;
        const isExpo = preBook.service.isExpo === true;
        const booking: Booking = {
            idBook: '',
            created: datesBooking.created,
            randomMultId: '',
            multCounter: [],
            objDoubleWorker: { workerIdA: '', workerIdB: '' },
            randomDoubleId: '',
            author: currentUser?.name as string,
            numberSesSer: preBook.service.numberSessions as number,
            momentIntervalIni: datesBooking.momentIntervalIni,
            momentIntervalEnd: datesBooking.momentIntervalEnd,
            amount: preBook.bono || preBook.ticketUsed ? 0 : parseFloat(preBook.service.price),
            serviceId: preBook.service.serviceId,
            workerId: preBook.workerName,
            customerUid: userDoc.uid,
            centerId: currentUser?.centerId as string,
            showingDate: datesBooking.showingDate,
            showingHour: datesBooking.showingHour,
            StartTime: datesBooking.StartTime,
            EndTime: datesBooking.EndTime,
            customerName: userDoc.displayName,
            customerPhone: userDoc.phone,
            customerEmail: userDoc.email,
            serviceName: preBook.service.serviceName,
            comesFromBono: preBook.bono || preBook.ticketUsed === true,
            doubleWorker: preBook.service.doubleWorker,
            paidOnline: false,
            paymentMethod: 'En el centro',
            paid: preBook.bono || preBook.ticketUsed || false,
            senderUid: doRandomUid(currentUser?.centerId as string),
            bonoSellId: preBook.bonoId,
            multiSer: preBooks.length > 1 || isNotFirstCase,
            firstTimeFromEst: datesBooking.isFirstTime,
            restTempo: isExpo ? toMinutes(preBook.service.expoArr[1].dur) : 0,
            isExpo: isExpo
        }
        return booking
    }

    const wasteBonoOrTicket = async (pre: MultPreSer[], batch: WriteBatch) => {
      const preTicket = pre.filter(el => el.ticketUsed === true);
      if (preTicket.length > 0) {
        for (const pre of preTicket) {
          const ticketUid = (await getDocs(query(collection(db, 'tickets'), where('code', '==', pre.bonoName)))).docs[0].id as string;
          if (ticketUid) {
            const refTicket = doc(db, `tickets/${ticketUid}`);
            const a = {id: pre.service.serviceId, name: pre.service.serviceName}
            batch.update(refTicket, {
              servicesUsed: arrayUnion(a)
            })
          }
        }
      }
      const tr = await trToWasteBono(pre);
      if (tr.length > 0) {
        for (const el of tr) {
          const refBono = doc(db, `sellsBonus/${el.id}`)
            if (el.servicesLimit === undefined) {
              batch.update(refBono, {
                numberSessions: el.numberSessions
              })
            } else {
              batch.update(refBono, {
                servicesLimit: el.servicesLimit
              })
            }
          }
        }
      }
    
    return {
        createFromFast,
        createFromBig,
        wasteBonoOrTicket
    }
}

export default useCreateBooking