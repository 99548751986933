import { BoldText } from '@components/ui/text/BoldText'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { closeSellsModal, selectEditBill } from '@redux/features/billSlice/billSlice'
import { nameintPosValid } from '@lib/helpers/Yup/nameintPosValid'
import FormField from '@components/formik/FormField'
import { Form } from '@components/formik/Form'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { CenterCol } from '@assets/sass/bootstrapEx'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import toast from 'react-hot-toast'
import { CrossSell } from '@models/sells/crossSell'
import { Spinner } from '@components/ui/spinner/Spinner'
import * as Sentry from '@sentry/react';

const initialValues = {
    efQnt: '',
}
const schema = Yup.object().shape({
    efQnt: Yup.number().required('Pon un número').transform(nameintPosValid),
})
const EditAmCross = () => {
    const dispatch = useAppDispatch();
    const { item, docId } = useAppSelector(selectEditBill)
    const [crossSell, setcrossSell] = useState(null as CrossSell | null)
    const [loading, setloading] = useState<boolean>(false)
    const total = crossSell ? crossSell.amount : 0;
    const [tarQnt, settarQnt] = useState(0)
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            try {
                const efQnt = Number(values.efQnt)
                if (efQnt <= total) {
                    await updateDoc(doc(db, `sells/${docId}`), {
                        efQnt: efQnt,
                        tarQnt: tarQnt,
                    })
                    toast.success('Cambiado');
                    dispatch(closeSellsModal())
                } else {
                    toast.error('Para cambiar la cantidad total utiliza la otra opción')
                }

            } catch (error) {
                Sentry.configureScope(scope => scope.setTransactionName('ErrorchangebillEditAm'))
                Sentry.captureException(error, { user: { id: `${docId}` } });
                toast.error('No se ha podido realizar el cambio contacta con un admin')
            }
        },
    })
    const efQntValue = formik.values.efQnt;
    useEffect(() => {
        const isNum = typeof Number(efQntValue) === 'number';
        if (efQntValue !== '' && isNum) {
            const ef = Number(efQntValue);
            const tar = total - ef
            settarQnt(tar)
        } else {
            settarQnt(0)
        }
    }, [efQntValue, total])
    useEffect(() => {
        let active = true;
        if (active) {
            const setCross = async () => {
                setloading(true)
                const cross = (await getDoc(doc(db, `sells/${docId}`))).data() as CrossSell;
                setcrossSell(cross)
                setloading(false)
            }
            setCross()
        }
        return () => {
            active = false;
        }
    }, [docId])
    return (
        <div>
            {
                loading ? <Spinner />
                :
                <div>
                    <BoldText text={`Total: ${total.toFixed(2)} €`} classNames='fw-bolder' />
                    <BoldText text='Estás editando las cantidades de toda la venta de pago mixto' />
                    <Form formikVal={formik}>
                        <FormField name={'efQnt'} label={`Cantidad en efectivo`} type={'text'} />
                        <BoldText classNames='text-success fs-3 mb-3' text={`Paga:  ${tarQnt > 0 ? tarQnt.toFixed(2) : '0'} € en tarjeta`} />
                        <div className={`${CenterCol} w-full`}>
                            <SubmitBtn loading={false} />
                        </div>
                    </Form>
                </div>  
            }
        </div>
    )
}

export default EditAmCross