import { Booking } from "@models/bookings/booking";
import { TimeV2 } from "@models/bookings/timeV2";

export const validateOneTime = (time: TimeV2, booking: Booking[]) => {
    time.valid = true;
    booking.forEach(b => {
    if (b.momentIntervalIni === time.str) {time.valid = false}
    else if (b.momentIntervalIni < time.str && b.momentIntervalEnd > time.str) {time.valid = false}
    else if (b.momentIntervalIni > time.str && b.momentIntervalIni < time.end && b.momentIntervalEnd >= time.end ) {time.valid = false}
    else if (time.str < b.momentIntervalIni && time.end >= b.momentIntervalEnd) {time.valid = false}
    });
    return time
  }