import { useState } from 'react'
import { CenterCol, Col } from '@assets/sass/bootstrapEx';
import { BoldText } from '@components/ui/text/BoldText';
import { UserCalendar } from './UserCalendar';
import { UserSearch } from '@components/common/usersearch/UserSearch';
import { UserFinal } from '@models/user/userFinal';
import { useFormikContext } from 'formik';
export const PickUser = () => {
  const { setFieldValue } = useFormikContext();
  const [needsRegister, setneedsRegister] = useState<boolean>(false);
  const toggleRegister = () => {
    setneedsRegister(!needsRegister)
  }
  const setClient = (us: UserFinal) => {
    setFieldValue('client', us.uid)
}
  return (
    <div className='w-100'>
      <div className={`${Col} w-100`}>
        <div className={` ${CenterCol} w-100 mb-5 ${needsRegister && 'd-none'}`}>
          <BoldText text='Selecciona un cliente para reservar' />
          <div className='w-300px'>
            <UserSearch userPicked={setClient} />
          </div>
          <p className='text-center is-pointer text-primary mt-5' onClick={toggleRegister}>¿No encuentras el cliente? Regístralo</p>
        </div>
        <UserCalendar needsRegister={needsRegister} toggleRegister={toggleRegister}  />
      </div>
    </div>
  )
}
