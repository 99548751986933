import { Field } from 'formik'
import React from 'react'
interface Props {
    name: string,
    value: string,
    title: string,
    classNames?: string
}
export const RadioBox = ({name, value, title, classNames}: Props) => {
  return (
    <div className='col'>
    <Field
      type='radio'
      className='btn-check'
      name={name}
      value={value}
      id={`kt_${value}`}
      onMouseDown={(e: any) => {
        e.preventDefault();
        e.target.focus();
        e.target.blur();
    }}
    />
    <label
      className={` btn btn-outline btn-outline-dashed btn-outline-default mt-3 ${classNames ? classNames : ''} `}
      htmlFor={`kt_${value}`}
    >
      <span className='fw-bolder fs-3 text-nowrap'>{title}</span>
    </label>
  </div>
  )
}
