import { Form } from '@components/formik/Form'
import { Acc } from '@components/ui/accordion/Acc'
import { Badge } from '@components/ui/badge/Badge'
import { BoldText } from '@components/ui/text/BoldText'
import { db } from '@lib/firebase'
import { Bonus } from '@models/bono/bonus'
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { toast } from 'react-hot-toast'
import { useFormik } from 'formik'
import { InlineSelect } from '@components/formik/InlineSelect'
import { JustEnd } from '@assets/sass/bootstrapEx'
import useServicesBooking from '@lib/hooks/calendar/book/useServicesBooking'
import { ActionButton } from '@components/ui/button/ActionButton'

export const BonoServices = (bono: Bonus) => {
    const { optsServices, services } = useServicesBooking([])

    const delServices = async (index: number) => {
        let s = bono.services;
        s = s.filter((_, i) => i !== index);
        await updateDoc(doc(db, `bonus/${bono.bonusId}`), {
            services: s
        })
        toast.success('Cambiado')
    }

    const formik = useFormik({
        initialValues: { currService: '' },
        onSubmit: async () => { },
    })

    const addService = async () => {
        const form = formik.values;
        const sel = services.filter(s => s.serviceId === form.currService)[0];
        if (bono.services.some(el => el.id === sel.serviceId)) {
            toast.error('Servicio ya incluído')
        } else {
            const a = {id: sel.serviceId, name: sel.serviceName}
            await updateDoc(doc(db, `bonus/${bono.bonusId}`), {
                services: arrayUnion(a)
            })
            toast.success('Cambiado')
        }
        formik.setFieldValue('currService', '')
    }


    return (
        <Acc title={'Servicios de todos los packs'} id={'AllServices'} classNames='mt-5'>
            <BoldText text={'¿Quieres eliminar algún servicio?'} classNames='text-center' />
            <div className='d-flex justify-content-start'>
                {
                    (bono.services).map((value, index) => (
                        <div key={value.id} className='position-relative fit-own me-3'>
                            <i className="fa-solid fa-x position-absolute top-0 end-0 is-pointer text-danger "
                                style={{ zIndex: 3 }} onClick={() => delServices(index)}></i>
                            <Badge text={`${value.name}`} type={'SUCC'} />
                        </div>
                    ))
                }
            </div>
            <Form formikVal={formik}>
                <BoldText text={'¿Quieres añadir algún servicio?'} classNames='text-center mt-5 mb-5' />
                <InlineSelect name='currService' opts={optsServices} search={true} label={'Elige los servicios'} />
                <div className={`${JustEnd} mt-3 mb-3`}>
                    {
                        formik.values.currService !== '' &&
                        <ActionButton text={'Añadir servicio'} action={addService} classNames='mt-3' />
                    }
                </div>
            </Form>
        </Acc>
    )
}
