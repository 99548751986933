import { BarItem } from '@components/ui/item/BarItem';
import { db } from '@lib/firebase'
import { selectUser } from '@redux/features/clientSlice/clientSlice';
import { useAppSelector } from '@redux/store/hooks';
import { format } from 'date-fns';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore'
import React, { useCallback, useEffect, useState } from 'react'

export const SeeWhats = () => {
    const user = useAppSelector(selectUser);
    const [whats, setwhats] = useState<any[]>([]);
    const findNameTem = useCallback(
        (name: string) => {
            switch (name) {
                case 'whnow2':
                    return 'Cita menos de 24h'
                case 'whmove2':
                    return 'Cita movida'
                case 'whcancel':
                    return 'Cita cancelada'
                case 'whday2':
                    return 'Cita con antelación'
                case 'confirmation':
                    return 'Confirmación'
                case 'tracking':
                    return 'Confirmación'
            }
        },
        [],
    )

    useEffect(() => {
        let active = true;
        if (active && user) {
            const getLastMessages = async () => {
                const q = query(collection(db, `messages`), where('to', '==', `34${user.phone}`), orderBy('delivery.endTime', 'desc'), limit(10));
                const ws = (await getDocs(q)).docs.map((v) => {
                    const data = v.data();
                    data.delivery.endTime = data.delivery.endTime.toDate();
                    const nameTem = data.content.hsm.templateName;
                    const ob = {
                        sended: data.delivery.endTime,
                        state: data.delivery.state,
                        to: data.to,
                        template: findNameTem(nameTem)
                    }
                    return ob;
                })
                setwhats(ws)
            }
            getLastMessages()
        }

        return () => {
            active = false;
        }
    }, [user, findNameTem])

    return (
        <div>
            {whats.map((value, index) => (
                <BarItem
                    key={index}
                    title={`Enviado ${format(value.sended, 'dd-MM-yyyy HH:mm')}`}
                    desc={`Tipo de mensaje ${value.template}, telf ${value.to} `}
                    back={'bg-success'} />
            ))}
        </div>
    )
}
