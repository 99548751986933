import { KTSVG, } from '_metronic/helpers'
import React, { useState } from 'react'
interface Props {
    passSearch: (val: string) => void,
    children?: React.ReactNode

}
export const TableHead = ({ passSearch, children }: Props) => {
    const [searchValue, setInputValue] = useState('')
    const [timer, setTimer] = useState<any>(null)
    const inputChanged = (e:any) => {
      setInputValue(e.target.value)
        if (timer) {
            clearTimeout(timer)
        }
  
      const newTimer = setTimeout(() => {
        if (searchValue.length > 2) {
            passSearch(searchValue as string)
        } else {
            passSearch('')
        }
        passSearch(searchValue)
      }, 100)
  
      setTimer(newTimer)
    }

    return (
        <div className='card-header border-0 ps-0 pt-5 d-flex flex-column'>
            <div className='card-title'>
                {/* begin::Search */}
                <div className='d-flex align-items-center position-relative my-1'>
                    <KTSVG
                        path='/media/icons/duotune/general/gen021.svg'
                        className='svg-icon-1 position-absolute ms-6'
                    />
                    <input
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid w-250px ps-14'
                        placeholder='Buscar'
                        value={searchValue}
                        onChange={inputChanged}
                    />
                </div>
                {/* end::Search */}
            </div>
            {
                children && children
            }
        </div>
    )
}
