import { SelectField } from '@components/formik/SelectField';
import { RadioBox } from '@components/formik/radio/box/RadioBox';
import { RadioBoxContainer } from '@components/formik/radio/box/RadioBoxContainer';
import { BoldText } from '@components/ui/text/BoldText';
import useHoursSer from '@lib/hooks/services/useHoursSer';
import { Expo, TypeExpo } from '@models/services/services';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

type V = {
    expoArr: Expo[]
}

const ExpoArr = () => {
    const { setFieldValue, values, } = useFormikContext();
    const {typeExpo, isExpo} = (values as any);
    const { optsDispo } = useHoursSer()

    useEffect(() => {
        const type = typeExpo as TypeExpo  
        const n = type === 'SERVICE_REST' ? 2 : (type === 'DOUBLE_TIME' ? 3 : 5);
        const arr: Expo[] = [];
        for (let index = 0; index < n; index++) {
            const t = { dur: '', rest: index % 2 === 0 ? false : true, index: index}
            arr.push(t)
        }
        setFieldValue('expoArr', arr)
    }, [typeExpo, setFieldValue])

    useEffect(() => {
        if (isExpo === 'no') {
            setFieldValue('expoArr', [])
        }
    }, [isExpo, setFieldValue])
    
    
    return (
        <div>
            <label className='col-lg-4 col-form-label required fw-bold fs-6 mb-5'>Servicio exposición</label>
            <div className='d-flex '>
                <RadioBoxContainer>
                    <RadioBox name={'typeExpo'} value={'SERVICE_REST'}
                        title={`Servicio + descanso`} />
                    <RadioBox name={'typeExpo'} value={'DOUBLE_TIME'}
                        title={`Dos servicios con descanso`} />
                    <RadioBox name={'typeExpo'} value={'TRIPLE_TIME'}
                        title={`Tres servicios con descansos`} />
                </RadioBoxContainer>
            </div>
            <div className='row mb-6'>
                {
                    (values as V).expoArr.map((item, i) => (
                        <div className='col-lg-6 fv-row mb-4' key={i}>
                            <BoldText text={!item.rest ? 'Tiempo de servicio' : 'Descanso'} classNames='mb-2' />
                            <SelectField className='me-3' opts={optsDispo} search={true} name={`expoArr.${i}.dur`} placeHolder={''} />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ExpoArr