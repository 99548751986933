import { TimeV2 } from "@models/bookings/timeV2";
import { getBookingFiltered } from "./getBookingFiltered";
import { validateOneTime } from "./validateOneTime";
import { Booking } from "@models/bookings/booking";

export const valBooksMul = (first: number, last: number, time: TimeV2, workerName: string, bookings: Booking[]) => {
    const workerBooks = getBookingFiltered(workerName, bookings);
    const filterMorOrAft = workerBooks.filter(book =>
        (book.momentIntervalIni >= first && book.momentIntervalEnd <= last)
        ||
        (book.momentIntervalIni < first && book.momentIntervalEnd > first)
        ||
        (book.momentIntervalIni > first && book.momentIntervalEnd > last)
    );
    const validatedTime: TimeV2 = validateOneTime(time, filterMorOrAft);
    return validatedTime;
}