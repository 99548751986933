import { db } from "@lib/firebase";
import { Ticket } from "@models/ticket/ticket"
import { getDocs, query, collection, where } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-hot-toast";

export default function useTicketSells(uid: string) {
    const [tickets, settickets] = useState<Ticket[]>([])
    const [showFinal, setshowFinal] = useState(0)
    const makePriceTicket = (price: number, code: string) => {
        const t = tickets.filter(ts => ts.code === code)[0];
        if (t) {
            const priceTicket = t.amount;
            let restTicket = 0;
            let restAmount = 0;
            if (priceTicket > price) {
                restTicket = (priceTicket - price);
                setshowFinal(0)
            } else {
                const finalAmount = (price - priceTicket);
                restTicket = finalAmount === 0 ? 0 : (priceTicket - price);
                restAmount = finalAmount
                setshowFinal(finalAmount)
            }
            return {restTicket, restAmount, ticketId: t.uid as string}
        } else {
            setshowFinal(0)
            toast.error('Ningún ticket con ese código o no le queda dinero')
            return null
        }
    }

    const fetchUserTicketsMoney = useCallback(
      async () => {
        const t = (await getDocs(query(collection(db, 'tickets'), 
        where('userReceives', '==', uid), 
        where('bono', '==', false), where('amount', '>', 0)))).docs.map((value) => { 
          const data = value.data();
          data.uid = value.id
          return data }) as Ticket[];
          settickets(t);
          console.log(t);
          
      },
      [uid],
    )
    

    useEffect(() => {
      let active = true;
      if (active === true) {
          fetchUserTicketsMoney()
      }
      return () => {
        active = false;
      }
    }, [fetchUserTicketsMoney])
    

    return {
        makePriceTicket,
        showFinal,
        tickets
    }

}