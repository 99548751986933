import { Product } from '@models/products/product'
import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Form } from '@components/formik/Form'
import { InlineInput } from '@components/formik/InlineInput'
import { SubmitField } from '@components/formik/SubmitField'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import toast from 'react-hot-toast'
import { useAppDispatch } from '@redux/store/hooks'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
const schema = Yup.object().shape({
  productName: Yup.string().required('Es necesario un nombre'),
  stock: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).typeError('Tiene que ser un número').test('more-than-zero', 'El precio debe ser igual a cero o superior', function (value: any) {
    return Number(value) === 0 || Number(value) > 0
  }),
  amount: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).typeError('Tiene que ser un número').test('more-than-zero', 'El precio debe ser igual a cero o superior', function (value: any) {
    return Number(value) === 0 || Number(value) > 0
  }), 
})
export const EditProd = (prod: Product) => {
  const dispatch = useAppDispatch()
  const formik = useFormik({
    initialValues: { productName: prod.productName, stock: `${prod.stock}`, amount: prod.amount },
    validationSchema: schema,
    onSubmit: async () => { }
  })
  const changeName = async () => {
    await updateDoc(doc(db, `products/${prod.productUid}`), {
      productName: formik.values.productName
    })
    toast.success('Cambiado')
    dispatch(setCloseModal())
  }
  const changeStock = async () => {
    await updateDoc(doc(db, `products/${prod.productUid}`), {
      stock: Number(formik.values.stock),
    })
    toast.success('Cambiado')
    dispatch(setCloseModal())

  }
  const changePrice = async () => {
    await updateDoc(doc(db, `products/${prod.productUid}`), {
      amount: formik.values.amount
    })
    toast.success('Cambiado')
    dispatch(setCloseModal())
  }

  return (
    <div className='p-4'>
      <Form formikVal={formik}>
        <InlineInput name={'productName'} label={`Cambia el nombre: ${prod.productName}`} />
        <div className='d-flex justify-content-end mb-5'>
          <SubmitField loading={false} handleSubmit={changeName} name={'productName'} text='Cambiar' />
        </div>
        <InlineInput name={'stock'} label={'Cambia el stock'} />
        <div className='d-flex justify-content-end mb-5'>
          <SubmitField loading={false} handleSubmit={changeStock} name={'stock'} text='Cambiar' />
        </div>
        <InlineInput name={'amount'} label={'Cambia el precio'} />
        <div className='d-flex justify-content-end mb-5'>
          <SubmitField loading={false} handleSubmit={changePrice} name={'amount'} text='Cambiar' />
        </div>
      </Form>
    </div>
  )
}
