import { closeSellsModal, selectEditBill } from '@redux/features/billSlice/billSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { useFormik } from 'formik'
import { Form } from '@components/formik/Form'
import { SubmitField } from '@components/formik/SubmitField'
import { RadioBoxContainer } from '@components/formik/radio/box/RadioBoxContainer'
import { BoldText } from '@components/ui/text/BoldText'
import { JustEnd, efIcon, tarIcon } from '@assets/sass/bootstrapEx'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { toast } from 'react-hot-toast'
import { RadioField } from '@components/formik/radio/RadioField'
import * as Sentry from '@sentry/react';
import { useAuth } from 'app/modules/auth'
import EditAmountBill from './EditAmountBill'
import { useBillContext } from '@lib/context/bill/BillCxt'


export const EditBill = () => {
    /* 
        review this with units, item will always be a simple sell that needs to look for the parent, so always pass parent id
    */
    const { sells } = useBillContext();
    const { centerId } = useAuth()
    const dispatch = useAppDispatch();
    const { docId, actions, item, type } = useAppSelector(selectEditBill)
    const formik = useFormik({
        initialValues: { paymentMethod: item ? item.paymentMethod : '', },
        onSubmit: async (values) => {
            if (item) {
                try {
                    const col = item.col ? item.col : 'sells';
                    const parent = sells.filter(sell => sell.docId === docId)[0];
                    const newSells = parent.sells.map((v) => 
                        ({...v, paymentMethod: v.uidFind === item.uidFind ? values.paymentMethod : v.paymentMethod}));
                    await updateDoc(doc(db, `${col}/${docId}`), {
                        sells: newSells
                    })
                    toast.success('Cambiado');
                    dispatch(closeSellsModal())
                } catch (error) {
                    Sentry.configureScope(scope => scope.setTransactionName('Errorchangebill'))
                    Sentry.captureException(error, { user: { id: `${docId} ${type} ${centerId}` } });
                    toast.error('No se ha podido realizar el cambio contacta con un admin')
                    dispatch(closeSellsModal())

                }
            }
        },
    })
    return (
        <div className='p-4'>

            {
                actions === 'AMOUNT' ?
                    <EditAmountBill />
                    :
                    <Form formikVal={formik}>
                        <div className=' w-100'>
                            <div className='mb-5 col-lg-8 fv-row'>
                                <RadioBoxContainer>
                                    <BoldText classNames='text-center mt-5' text={`Selecciona un método de pago para ${item!.name}`} />
                                    <RadioField icon={tarIcon} name={'paymentMethod'} value={'Tarjeta de crédito'} title={`Tarjeta de crédito `} />
                                    <RadioField icon={efIcon} name={'paymentMethod'} value={'Efectivo'} title={`Efectivo `} />
                                </RadioBoxContainer>
                                <div className={`${JustEnd} mt-5`}>
                                    <SubmitField name={'paymentMethod'} loading={false} handleSubmit={() => formik.submitForm()} />
                                </div>
                            </div>
                        </div>
                    </Form>
            }
        </div>
    )
}
