import useBill from '@lib/hooks/bill/useBill';
import { selectType } from '@redux/features/billSlice/billSlice';
import { useAppSelector } from '@redux/store/hooks';
import { InfoBillCard } from './InfoBillCard';
import { ItemBill } from './ItemBill';
import { BoldText } from '@components/ui/text/BoldText';
import { selectPureWorkers } from '@redux/features/gquerySlice/gquerySlice';
import { useEffect, useState } from 'react';
type DataChart = {
    names: any[],
    amounts: any[]
}
export const ShowByWorker = () => {
  const workers = useAppSelector(selectPureWorkers)
  const currType = useAppSelector(selectType);
  const t = currType !== null ? currType : null;
  const [dataChart, setdataChart] = useState<DataChart>({names: [], amounts: []})
  const {clean} = useBill(t)
  useEffect(() => {
        const names: any[] = [];
        const amounts: any[] = [];
        const f = t !== null ? clean.filter(el => el.type === t) : clean;
        f.forEach(o => {
          if (!names.includes(o.workerName)) {
            names.push(o.workerName)
          }
        });
        names.forEach(name => {
          // filtres l'clean
          const amountPerW = f.filter(o => o.workerName === name);
          const totalAmPerW = amountPerW.reduce((a, b) => +a + +b.amount, 0);
          amounts.push(totalAmPerW);
        })
        setdataChart({names: names, amounts: amounts})
  }, [clean, t])
  
  return (
    <InfoBillCard 
    color={'danger'}  
    title={'Ventas por emplead@'} 
    text={''} 
    total={`Total ventas por emplead@ ${t !== null ? clean.filter(el => el.type === t).length : clean.length}`} 
    children={
        <>
            {
                dataChart.amounts.length === 0 ? <BoldText text='No hay ninguna venta' classNames='text-center' /> :  null
            }
            {
                dataChart.names.map((v, index) => (
                    <ItemBill 
                    key={index} 
                    icon={v.slice(0, 1)} 
                    av={workers.some(w => w.name === v) ? workers.filter(w => w.name === v)[0].avatar : '002-girl'}
                    title={v} 
                    emplo={true}
                    total={dataChart.amounts[index].toFixed(2)
                    } />
                ))
            }
        </>
    } />
  )
}
