import { FC, createContext, useContext, useState, useEffect, useCallback } from "react";
import * as Sentry from '@sentry/react';
import { useAppDispatch } from "@redux/store/hooks";
import { setCloseModal, showModal } from "@redux/features/uiSlice/uiSlice";
import { useAuth } from "app/modules/auth";
import { arrayUnion, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "@lib/firebase";




export interface VersionState {
    currVersion: number
    downloadVersion: number
}
const initialVersion = {
    currVersion: 0,
    downloadVersion: 0
}

export const VersionCxt = createContext<VersionState>(initialVersion)

interface childs {
    children: React.ReactNode
}

export const VersionContext: FC<childs> = (props) => {
    const [versionNoError, setNoError] = useState(false)
    const [version, setVersion] = useState<VersionState>({ currVersion: 0, downloadVersion: 0 })
    const { currentUser } = useAuth()

    const dispatch = useAppDispatch();
    // https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0

    const saveVersionCenter = useCallback(async () => {
        if (currentUser && (currentUser?.role.includes('admin') || currentUser?.role.includes('worker'))) {
            try {
                const version = (await fetch('/meta.json', { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }).then((res) => {
                    return res.json()
                }))
                await updateDoc(doc(db, `users/${currentUser.uid}`), {
                    buildNumber: version.buildNumber
                })
            } catch (error) {
                Sentry.configureScope(scope => scope.setTransactionName('Error could not update user version'))
                Sentry.captureException(error);
            }
        }
    }, [currentUser])
    useEffect(() => {
        let active = true;
        if (active) {
            saveVersionCenter()
        }

        return () => {
            active = false;
        }
    }, [saveVersionCenter])

    useEffect(() => {
        const sub = onSnapshot(doc(db, 'rewrite/wKT4KNcL3rs7GMHQCIEZ'), (snap) => {
          const rewrite = snap.data()
          if (rewrite) {
              if (rewrite.isRewriteAdmin === true) {
                dispatch(showModal({ view: 'IS_REWRITE' }))
              } else {
                dispatch(setCloseModal())
              }
          }
        })
        return () => {
            sub()
        }
      }, [dispatch])

    useEffect(() => {
        let active = true;
        if (process.env.NODE_ENV === 'production' && active) {
            const getFirstBuild = async () => {
                try {
                    const version = (await fetch('/meta.json', { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }).then((res) => {
                        return res.json()
                    }))
                    setVersion({ currVersion: version.buildNumber, downloadVersion: 0 })
                    setNoError(true)
                } catch (error) {
                    Sentry.configureScope(scope => scope.setTransactionName('Error version not found'))
                    Sentry.captureException(error);
                }
            }
            getFirstBuild()
        }

        return () => {
            active = false;
        }
    }, [])



    useEffect(() => {
        if (process.env.NODE_ENV === 'production' && versionNoError === true) {
            const interval = setInterval(() => {
                const getBuildVersion = async () => {
                    try {
                        const version = (await fetch('/meta.json', { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }).then((res) => {
                            return res.json()
                        }))
                        setVersion((prev) => {
                            return {
                                currVersion: prev.currVersion,
                                downloadVersion: version.buildNumber
                            }
                        })
                    } catch (error) {
                        dispatch(showModal({ view: 'NEW_VERSION' }))
                    }
                }
                getBuildVersion()
            }, 1800000);
            return () => clearInterval(interval);
        }
    }, [versionNoError, dispatch])



    return <VersionCxt.Provider value={version} {...props} />
}


export const useVerCxt = () => {
    const context = useContext<VersionState>(VersionCxt);
    return context;
}