
import { DOTS, usePagination } from '@lib/hooks/ui/usePagination.';
import classnames from 'classnames';
import '@assets/sass/customPag.scss'
import { BoldText } from '../text/BoldText';
interface PaginationProps {
  className: string
  /**
   * The total number of results
   */
  totalResults: number;
  /**
   * The total number of results
   */
  currentPage: number;
  /**
   * The number of results shown per page
   */
  resultsPerPage: number;
  /**
   * The accessible name of the pagination (what does it refer to?)
   */
  label?: string;
  /**
   * The function executed on page change
   */
  onChange: (activePage: number) => void;
}

export const TablePag = ({
  totalResults,
  currentPage,
  resultsPerPage,
  onChange,
  className
}: PaginationProps) => {

  const paginationRange: any = usePagination({
    totalResults,
    resultsPerPage,
    currentPage
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onChange(currentPage + 1);
  };

  const onPrevious = () => {
    onChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className='d-flex flex-column'>
      <div style={{paddingLeft: '2rem'}}>
        <BoldText text={`Total: ${totalResults}`} />
      </div>
      <ul
        className={classnames('pagination_est', { [className]: className })}
      >
        <li
          className={classnames('pagination-item', {
            disabled: currentPage === 1
          })}
          onClick={onPrevious}
        >
          <div className="arrow left" />
        </li>
        {paginationRange.map((pageNumber: any, i: number) => {
          if (pageNumber === DOTS) {
            return <li key={`${new Date().getMilliseconds()}-${i}`} className="pagination-item dots">&#8230;</li>;
          }

          return (
            <li key={`${new Date().getMilliseconds()}-${i}`}
              className={classnames('pagination-item', {
                selected: pageNumber === currentPage
              })}
              onClick={() => onChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          className={classnames('pagination-item', {
            disabled: currentPage === lastPage
          })}
          onClick={onNext}
        >
          <div className="arrow right" />
        </li>
      </ul>
      
    </div>
  )
}