import { TypeSells } from '@models/sells/helpersSells';
import { useEffect, useState } from 'react'
import { TableBill } from './TableBill';
import useBill from '@lib/hooks/bill/useBill';
type State = {
  PRODS: any[];
  TICKS: any[];
  BONUS: any[];
  BOOKS: any[];
};
const types: TypeSells[] = ['PRODS', 'BONUS', 'BOOKS', 'TICKS']
export const ShowBillTables = () => {
  const {outputs} = useBill(null)
  const [arrs, setarrs] = useState<State>({PRODS: [], TICKS: [], BONUS: [], BOOKS: []})
  useEffect(() => {
    for (let index = 0; index < types.length; index++) {
      const t = types[index];
      const filt = outputs.filter(a => a.type === t)
      setarrs((prev) => {
        return {
        ...prev,
        [t]: filt
        }
      })
      
    }

  }, [outputs])
  
  return (
    <div>
      {
        types.map((v, i) => (
          <TableBill key={i} arr={arrs[v]} type={v} />
        ))
      }

    </div>
  )
}
