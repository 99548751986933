import { BonusSell } from "@components/common/wiz/wiztypes/BonoSellsWizHelper";
import { db } from "@lib/firebase";
import { makeDatesForSell } from "@lib/helpers/sells/makeDatesForSell";
import { SellsBonus } from "@models/bono/sellsBonus";
import { UserFinal } from "@models/user/userFinal";
import { collection, doc,  writeBatch } from "firebase/firestore";
import * as Sentry from '@sentry/react';
import { Sell, SimpleSell } from "@models/sells/sell";
import { useAppSelector } from "@redux/store/hooks";
import { SellsCounter } from "@models/sells/sellsCounter";
import { selectSellsCounter } from "@redux/features/gquerySlice/gquerySlice";
import { PayMeth } from "@models/paymentMetod/payMeth";
import { doRandomUid } from "@lib/helpers/random/doRandomUid";
import { format } from "date-fns";
import { commitBatch } from "@lib/helpers/common/commitBatch";


export default function useAddSellsBonus() {
    // cloud to delete:  const sendCustomReceipt = callCloud('sendCustomReceipt');
    const counter = useAppSelector(selectSellsCounter) as SellsCounter;
    const addSellBonus = async (
        values: BonusSell,
        user: UserFinal,
        method: string,
    ) => {
        try {
            const batch = writeBatch(db);
            const { dateShow, dateTime } = makeDatesForSell();
            const refSellsBonus = collection(db, 'sellsBonus');
            const id = doc(refSellsBonus).id;
            const sellsBonus: SellsBonus = {
                id: id,
                paymentMethod: method,
                centerId: user.centerClient,
                name: values.selBonus!.nameBonus,
                services: values.selBonus!.services,
                userUid: user.uid,
                amount: values.simple === 'Simple' ? values.finalPrice.toFixed(2) : values.firstPayment,
                dateTime: dateTime,
                dateShow: dateShow,
                optFac: values.workerName,
                customerName: user.displayName,
                simplePay: values.simple === 'Simple' ? true : false,
                userEmail: user.email,
                firstPayment: true,
                leftToPay: values.simple === 'Frac' ? values.finalPrice - Number(values.firstPayment) : 0,
                originalAmount: values.finalPrice,
                originBono: values.selBonus!.bonusId
            }
            if (values.selBonus!.pers) { sellsBonus.servicesLimit = values.packSel!.servicesLimit } else { sellsBonus.numberSessions = values.packSel!.sessionPack }
            const simpleSell: SimpleSell = {
                name: sellsBonus.name,
                amount: Number(sellsBonus.amount),
                workerName: values.workerName,
                type: "BONUS",
                orderDate: format(new Date(), 'dd-MM-yyyy HH:mm'),
                parentId: id,
                paymentMethod: method as PayMeth,
                userName: user.displayName,
                userUid: sellsBonus.userUid,
                uidFind: doRandomUid(sellsBonus.centerId),
                uidInCol: sellsBonus.originBono
            }
            const sell: Sell = {
                uidInCol: sellsBonus.originBono,
                docId: id,
                dateTime: dateTime,
                centerId: sellsBonus.centerId,
                cross: false,
                userUid: sellsBonus.userUid,
                userName: user.displayName,
                email: user.email,
                amount: Number(sellsBonus.amount),
                paymentMethod: method as PayMeth,
                allTypes: ["BONUS"],
                sells: [simpleSell],
                counter: 0,
                cif: counter.cif,
                busName: counter.busName,
                dir: counter.dir,
                postal: counter.postal,
                isAdmin: true
            }
            batch.set(doc(db, `sellsBonus/${id}`), sellsBonus)
            batch.set(doc(db, `sells/${id}`), sell)
            await commitBatch(batch);
            return true
        } catch (error) {
            Sentry.configureScope(scope => scope.setTransactionName('Error at create bono'))
            Sentry.captureException(error);
            return false
        }

    }

    return {
        addSellBonus,
    }



}