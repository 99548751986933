import { Services } from '@models/services/services'
import * as Yup from 'yup'

export interface TicketForm {
    code: string,
    workerName: string,
    amount: string,
    centerId: string,
    client: '',
    bono: boolean,
    type: string
    currService: string,
    services: Services[]

}

const initTicketForm: TicketForm = {
    workerName: '',
    code: '',
    amount: '',
    centerId: '',
    bono: false,
    type: '', 
    client: '', 
    currService: '',
    services: []
}

const TicketFormSchema = [
    Yup.object().shape(
        {
            workerName: Yup.string().required('Selecciona el emplead@'),
            amount: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).required('Pon un precio').positive('Número positivo').typeError('Tiene que ser un número'),
            type: Yup.string().required('Selecciona un tipo'),
            servicesInc: Yup.array().min(1)
        }
    ),
]

export {TicketFormSchema, initTicketForm}