import TableCnt from '@components/ui/table/OptTable/TableCnt'
import { KTCard } from '_metronic/helpers'
import { useAuth } from 'app/modules/auth'
import { where } from 'firebase/firestore'
const heads = ['Número', 'Total', 'Cliente', 'Fecha', 'Visualizar', 'Acciones']

export const TableSells = () => {
    const { centerId } = useAuth();
    const q = [where('counter', '>', 0), where('centerId', '==', centerId)]


    return (
        <KTCard>
            <TableCnt filterDate={true} needsSearch={false} nameCol={'sells'} queryCons={q} nameOrder={'dateTime'} desc={true} heads={heads} />
        </KTCard>
    )
}
