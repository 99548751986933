import { BlockCenter, efIcon, tarIcon } from '@assets/sass/bootstrapEx';
import { BonusSell } from '@components/common/wiz/wiztypes/BonoSellsWizHelper';
import FormField from '@components/formik/FormField';
import { SubmitField } from '@components/formik/SubmitField';
import { RadioContainer } from '@components/formik/radio/RadioContainer';
import { RadioField } from '@components/formik/radio/RadioField';
import { Acc } from '@components/ui/accordion/Acc';
import { ActionButton } from '@components/ui/button/ActionButton';
import { ResCol } from '@components/ui/container/ResCol';
import { SimpleCard } from '@components/ui/item/SimpleCard';
import { Spinner } from '@components/ui/spinner/Spinner';
import { db } from '@lib/firebase';
import { doPer } from '@lib/helpers/sells/doPer';
import useAddSellsBonus from '@lib/hooks/sells/useAddSellsBonus';
import useTicketSells from '@lib/hooks/sells/useTicketSells';
import { UserFinal } from '@models/user/userFinal';
import { selectUser } from '@redux/features/clientSlice/clientSlice';
import { setCloseModal } from '@redux/features/uiSlice/uiSlice';
import { useAppDispatch, useAppSelector } from '@redux/store/hooks';
import { doc, updateDoc } from 'firebase/firestore';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';

export const StepBonus2 = () => {
    const dispatch = useAppDispatch()
    const [loading, setloading] = useState(false)
    const user = useAppSelector(selectUser)
    const { setFieldValue, values } = useFormikContext<BonusSell>();
    const { makePriceTicket, tickets } = useTicketSells(user?.uid as string);
    const { addSellBonus, } = useAddSellsBonus()
    const changePrice = () => {
        const n = Number(values.newPrice);
        setFieldValue('finalPrice', n);
        setFieldValue('newPrice', '');
        toast.success('Precio cambiado')
    }
    const makeDiscount = () => {
        const n = Number(values.discount);
        const disc = doPer(n, values.finalPrice)
        setFieldValue('finalPrice', (values.finalPrice - disc));
        setFieldValue('discount', '');
        toast.success('Precio cambiado')
    }
    const processTicket = async () => {
        const code = values.code;
        const opTicket = makePriceTicket(values.finalPrice, code);
        setloading(true);
        if (opTicket) {
            const { restAmount, restTicket, ticketId } = opTicket;
            if (restAmount > 0) {
                setFieldValue('finalPrice', restAmount)
                toast.success('Selecciona cómo pagar el resto, el ticket ya ha sido gastado')
                await updateDoc(doc(db, `tickets/${ticketId}`), {
                    amount: restTicket
                })
            } else {
                const isGood = await addSellBonus(values, user as UserFinal, 'ticket')
                if (isGood) {
                    dispatch(setCloseModal())
                    toast.success('Venta realizada')
                } else {
                    toast.error('Error al crear la venta')
                }

            }
        }
        setloading(true);
    }

    useEffect(() => {
        if (values.packSel) {
            setFieldValue('finalPrice', Number(values.packSel.amountPack))
        } else {
            setFieldValue('finalPrice', 0)
        }
    }, [values.packSel, setFieldValue])



    useEffect(() => {
        if (values.indexPack !== null) {
            const i = Number(values.indexPack)
            const pack = values.selBonus?.sessionsPack[i];
            setFieldValue('packSel', pack)
        } else {
            setFieldValue('packSel', null)
        }
    }, [values.indexPack, setFieldValue, values.selBonus])



    return (
        <div className='w-100'>
            <div className='d-flex justify-content-center mb-5'>
                {
                    values.packSel !== null ?
                        <SimpleCard
                            childIcon={<i className={`${values.packSel.servicesLimit ? 'fa fa-paint-brush' : 'fa-solid fa-gift'} text-white`}
                                style={{ fontSize: 35 }}></i>}
                            classNames='w-300px h-150px mt-5'
                            title={values.packSel.name}
                            text1={`Precio ${values.finalPrice.toFixed(2)} € `}
                            back={values.packSel.servicesLimit ? 'bg-success' : 'bg-info'} />
                        : null
                }
            </div>
            <ResCol
                childrenFirst={
                    <>
                        <div>
                            <FormField name={'discount'} label={'Aplica un porcentaje al precio del bono'} type={'text'} />
                            <SubmitField   name={'discount'} loading={false} handleSubmit={makeDiscount} />
                        </div>
                    </>
                }
                childrenSecond={
                    <>
                        <div>
                            <FormField name={'newPrice'} label={'Cambiar el precio'} type={'text'} />
                            <SubmitField   name={'newPrice'} loading={false} handleSubmit={changePrice} />
                        </div>
                    </>
                }
            />
            {
                values.simple === 'Frac' ?
                    <FormField name={'firstPayment'} label={'Pago la primera vez'} type={'text'} />
                    : null
            }
            {
                tickets.length > 0 ?
                    <Acc title={'Usar ticket'} id={'Ticket'}>
                        <FormField name={'code'} label={'Escribe el código'} type={'text'} />
                        {
                            values.code !== '' &&
                            (
                                loading ?
                                    <Spinner />
                                    :
                                    <ActionButton
                                        classNames={`${BlockCenter} mt-4`}
                                        text='Usar ticket'
                                        action={processTicket} />
                            )
                        }
                    </Acc>
                    : null
            }
            <RadioContainer title={'Selecciona un método de pago'} >
                <RadioField title='Tarjeta de crédito' name='method' value='Tarjeta de crédito'
                    icon={tarIcon}
                />
                <RadioField title='Efectivo' name='method' value='Efectivo'
                    icon={efIcon}
                />
            </RadioContainer>
        </div>
    )
}