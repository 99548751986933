import { Services } from '@models/services/services'
import { selectCats, selectServices } from '@redux/features/gquerySlice/gquerySlice'
import { selectorEditId, setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import React, { useEffect, useState } from 'react'
import { InfoSer } from './InfoSer'
import { CatSer } from './CatSer'
import { DescSer } from './DescSer'
import { SerAdition } from './SerAdition'
import { ImgSer } from './ImgSer'
import { DelOp } from '@components/common/del/DelOp'
import { collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { Booking } from '@models/bookings/booking'
import toast from 'react-hot-toast'
import * as Sentry from '@sentry/react';
import Expo from './Expo'
import { EditSerName } from './EditSerName'

export const EditService = () => {
    const id = useAppSelector(selectorEditId)
    const dispatch = useAppDispatch();
    const services = useAppSelector(selectServices)
    const cats = useAppSelector(selectCats)
    const [ser, setser] = useState<Services | null>(null)
    useEffect(() => {
        const f = services.filter(s => s.serviceId === id)[0];
        if (f) {
            setser(f);
        }
    }, [id, services])

    const deleteService = async () => {
        let today = new Date();
        try {
            const bQuery = query(collection(db, 'bookings'),
                where('centerId', '==', ser?.centerId),
                where('serviceId', '==', ser?.serviceId),
                where('StartTime', '>=', today))
            const books = (await getDocs(bQuery)).docs.map((value) => value.data()) as Booking[];
            if (books.length > 0 && books.some(b => b.cancelled === undefined)) {
                const mess = books.map(value => `${value.serviceName}, ${value.showingDate}, ${value.showingHour}`)
                    .join('/ ');
                toast.error(`Sin cancelar: ${mess}`)
            } else {
                await deleteDoc(doc(db, `services/${ser?.serviceId}`))
                dispatch(setCloseModal())
            }
        } catch (error) {
            console.log(error)
            Sentry.configureScope(scope => scope.setTransactionName('Error delete service'))
            Sentry.captureException(error);
            toast.error('Ha habido algún error')
        }
    }


    return (
        <div className='p-4'>
            {
                ser ?
                    <>
                        <EditSerName ser={ser} />
                        <InfoSer ser={ser} />
                        {
                            cats.length > 0 &&
                            <CatSer serviceId={ser.serviceId} cats={cats} currCat={ser!.cat as string} />
                        }
                        <DescSer serviceId={ser.serviceId} currDesc={ser.description} />
                        <SerAdition ser={ser} />
                        <ImgSer url={ser.img} serviceId={ser.serviceId} />
                        <Expo ser={ser} />
                        <DelOp del={deleteService} quest={'¿Quieres eliminar el servicio?'} text={'Eliminar'} />

                    </>
                    : null
            }
        </div>
    )
}
