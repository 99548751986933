import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { Form } from '@components/formik/Form'
import { useAuth } from 'app/modules/auth'
import { InlineInput } from '@components/formik/InlineInput'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { Product } from '@models/products/product'
import { collection, doc, setDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import toast from 'react-hot-toast'
const initialValues = { productName: '', stock: '', amount: '' }
const schema = Yup.object().shape({
  productName: Yup.string().required('Es necesario un nombre'),
  stock: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).typeError('Tiene que ser un número').integer('El número debe ser entero'),
  amount: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).typeError('Tiene que ser un número'),
})

export const CreateProd = () => {
  const { centerId } = useAuth();
  const [loading, setloading] = useState<boolean>(false)
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setValues, resetForm }) => {
      const prodCol = collection(db, 'products');
      const id = doc(prodCol).id;
      setloading(true)
      const p: Product = {
        centerId: centerId,
        productName: values.productName,
        stock: Number(values.stock),
        amount: values.amount,
        productUid: id
      }
      await setDoc(doc(db, `products/${p.productUid}`), p)
      toast.success('Producto creado')
      setloading(false)
      setValues({ ...initialValues })
      resetForm();
    },
  })
  return (
    <div className='p-4'>
      <Form formikVal={formik}>
        <InlineInput name={'productName'} label={'Nombre del producto'} />
        <InlineInput name={'stock'} label={'Stock del producto'} />
        <InlineInput name={'amount'} label={'Precio del producto'} />
        <div className={`${JustEnd} mt-5`}>
          <SubmitBtn loading={loading} />
        </div>
      </Form>
    </div>
  )
}
