import { KTSVG } from '_metronic/helpers'
import { useFormikContext } from 'formik';
import { WizContent } from './Wiz';
import { useMemo } from 'react';
interface Props {
    isSubmitButton: boolean,
    currentIndex: number,
    type: string
}
export const SubmitWiz = ({ isSubmitButton, currentIndex, type }: Props) => {
    const { isValid, values} = useFormikContext<any>();
    const isDisabledByMult = useMemo(() => {
        const t = type as WizContent;
        if (t === 'BIG_BOOK' || t === 'FAST_BOOK') {
            if (currentIndex === 2) {
                if (values.multArr.length === 0) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }, [values.multArr, currentIndex, type])
    const isDisabledByTime = useMemo(() => {
        const t = type as WizContent;
        if (t === 'BIG_BOOK') {
            if (currentIndex === 3) {
                if (values.time === null) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }, [values.time, currentIndex, type])
    const isDisabledByMethod = useMemo(() => {
        const t = type as WizContent;
        if (t === 'BONUS_SELL') {
            if (currentIndex === 2) {
                if (values.method === '') {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }, [values.method, type, currentIndex])
    const isOnePack = useMemo(() => {
        const t = type as WizContent;
        if (t === 'BONO_FORM' && currentIndex === 2) {
            if (values.sessionsPack.length === 0) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }, [values.sessionsPack, type, currentIndex])
    const isServiceBono = useMemo(() => {
        const t = type as WizContent;
        if (t === 'BONO_FORM' && currentIndex === 2 && values.pers === false) {
            if (values.services.length === 0) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }, [values.services, type, currentIndex, values.pers])
    return (
        <button
            disabled={!isValid || isDisabledByMult || isDisabledByTime || isDisabledByMethod || isOnePack || isServiceBono}
            type='submit'
            className='btn btn-lg btn-est text-white me-3'
        // disabled={Object.keys(errors).length > 0}
        >
            <span className='indicator-label'>
                {!isSubmitButton && 'Continuar'}
                {isSubmitButton && (type === 'TICKET_FORM' ? 'Terminar' : 'Enviar')}
                <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                />
            </span>
        </button>
    )
}
