import { KTSVG } from '_metronic/helpers'
import React from 'react'

interface Props {
    color: string,
    children: React.ReactNode
    title: string,
    text: string,
    total: string

}

export const InfoBillCard = ({color, title, text, total, children}: Props) => {
  return (
    <div className={`card card-xl-stretch mb-xl-8`}>
      <div className='card-body p-0'>
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>{title}</h3>
            {/* <div className='ms-1'>
              <button
                type='button'
                className={`btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-${color} border-0 me-n3`}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
              </button>
            </div> */}
          </div>
          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'>{text}</span>
            <span className='fw-bold fs-2x pt-1'>{total}</span>
          </div>
        </div>
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >
            {children}
        </div>
      </div>
    </div>
  )
}
