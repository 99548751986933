import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { selectPayLeft, } from '@redux/features/clientSlice/clientSlice'
import { Form } from '@components/formik/Form'
import FormField from '@components/formik/FormField'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { cloneDeep } from 'lodash'
import { toast } from 'react-hot-toast'
import { makeDatesForSell } from '@lib/helpers/sells/makeDatesForSell'
import { collection, doc, getDoc, writeBatch } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { SellsBonus } from '@models/bono/sellsBonus'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { tarIcon, efIcon } from '@assets/sass/bootstrapEx'
import { RadioContainer } from '@components/formik/radio/RadioContainer'
import { RadioField } from '@components/formik/radio/RadioField'
import { addSellPayLeft } from '@lib/helpers/sells/addSellPayLeft'
import { selectSellsCounter } from '@redux/features/gquerySlice/gquerySlice'
import { SellsCounter } from '@models/sells/sellsCounter'
import { commitBatch } from '@lib/helpers/common/commitBatch'
const initialValues = { newLeft: '', method: '' }
const schema = Yup.object().shape({
    newLeft: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).positive('Número positivo').typeError('Tiene que ser un número').required('Falta pago'),
    method: Yup.string().required('Elige el método')
})

export const PayLeft = () => {
    const dispatch = useAppDispatch()
    const { leftToPay, bonoId } = useAppSelector(selectPayLeft)
    const counter = useAppSelector(selectSellsCounter) as SellsCounter;
    const [loading, setloading] = useState<boolean>(false);
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            setloading(true)
            if (Number(values.newLeft) > leftToPay) {
                toast.error('Introduce una cantidad inferior')
                setloading(false);
            }
            const batch = writeBatch(db);
            const { dateTime } = makeDatesForSell()
            const n = Number(values.newLeft);
            const currLeft = leftToPay - n;
            const sellsCol = collection(db, 'sells')
            const ref = doc(sellsCol);
            const originDoc = (await getDoc(doc(db, `sellsBonus/${bonoId}`))).data() as SellsBonus;
            const clone = cloneDeep(originDoc);
            clone.firstPayment = false;
            delete clone.id;
            clone.id = ref.id
            clone.dateTime = dateTime;
            clone.paymentMethod = values.method;
            clone.amount = n.toFixed(2);
            const sell = await addSellPayLeft(clone, counter);
            batch.set(doc(db, `sells/${clone.id}`), sell);
            batch.update(doc(db, `sellsBonus/${bonoId}`), {
                leftToPay: currLeft
            });
            await commitBatch(batch);
            toast.success(`Falta por pagar ${currLeft.toFixed(2)}`)
            setloading(false)
            dispatch(setCloseModal())
        },
    })
    return (
        <div className='p-4'>
            <Form formikVal={formik}>
                <FormField name='newLeft' label={'Cantidad que se va pagar'} type={'text'} />
                <RadioContainer title={'Selecciona un método de pago'} >
                    <RadioField title='Tarjeta de crédito' name='method' value='Tarjeta de crédito'
                        icon={tarIcon}
                    />
                    <RadioField title='Efectivo' name='method' value='Efectivo'
                        icon={efIcon}
                    />
                </RadioContainer>
                <div className={` d-flex justify-content-end mt-10`}>
                    <SubmitBtn loading={loading} />
                </div>
            </Form>
        </div>
    )
}
