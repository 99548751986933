import { BlockCenter, efIcon, tarIcon } from '@assets/sass/bootstrapEx'
import { Form } from '@components/formik/Form'
import FormField from '@components/formik/FormField'
import { SelectField } from '@components/formik/SelectField'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { RadioContainer } from '@components/formik/radio/RadioContainer'
import { RadioField } from '@components/formik/radio/RadioField'
import { Acc } from '@components/ui/accordion/Acc'
import { ActionButton } from '@components/ui/button/ActionButton'
import { ResCol } from '@components/ui/container/ResCol'
import { SimpleCard } from '@components/ui/item/SimpleCard'
import { Spinner } from '@components/ui/spinner/Spinner'
import { db } from '@lib/firebase'
import useProdsSells from '@lib/hooks/sells/useProdsSells'
import useTicketSells from '@lib/hooks/sells/useTicketSells'
import useWorkersSells from '@lib/hooks/sells/useWorkersSells'
import { Product } from '@models/products/product'
import { selectUser } from '@redux/features/clientSlice/clientSlice'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { doc, increment, updateDoc, writeBatch } from 'firebase/firestore'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { addSellProd } from '@lib/helpers/sells/addSellProd'
import { SellsCounter } from '@models/sells/sellsCounter'
import { selectSellsCounter } from '@redux/features/gquerySlice/gquerySlice'
import { UserFinal } from '@models/user/userFinal'
import { commitBatch } from '@lib/helpers/common/commitBatch'

const initialValues = { workerName: '', code: '', prodId: '', method: '' }
const schema = Yup.object().shape({
    prodId: Yup.string().required('Selecciona el producto'),
    workerName: Yup.string().required('Selecciona el emplead@'),
    method: Yup.string().required('Selecciona el método de pago'),
})

export const AddProd = () => {
    const dispatch = useAppDispatch()
    const counter = useAppSelector(selectSellsCounter) as SellsCounter;
    const user = useAppSelector(selectUser);
    const [loading, setloading] = useState<boolean>(false);
    const { showFinal, makePriceTicket, tickets } = useTicketSells(user?.uid as string);
    const { prods, prodsOpts } = useProdsSells(user?.centerClient as string);
    const { worOpts } = useWorkersSells()
    const [selProd, setselProd] = useState<Product | null>(null)
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            setloading(true)
            const { method, prodId } = values;
            const prod = prods.filter(el => el.productUid === prodId)[0];
            await productSelect(prod, method, showFinal === 0 ? Number(prod.amount) : showFinal)
            setloading(false)
            dispatch(setCloseModal())
        },
    })
    const processTicket = async () => {
        setloading(true)
        const code = formik.values.code;
        const prod = prods.filter(el => el.productUid === formik.values.prodId)[0];
        const opTicket = makePriceTicket(Number(prod.amount), code);
        if (opTicket) {
            const { restAmount, restTicket, ticketId } = opTicket;
            if (restAmount > 0) {
                toast.success('Selecciona cómo pagar el resto, el ticket ya ha sido gastado')
                await updateDoc(doc(db, `tickets/${ticketId}`), {
                    amount: restTicket
                })
            } else {
                await productSelect(prod, 'ticket', 0);
                await updateDoc(doc(db, `tickets/${ticketId}`), {
                    amount: restTicket
                })
            }
        }
        setloading(false)

    }

    const productSelect = async (prod: Product, pay: string, amount: number) => {
        const batch = writeBatch(db);
        const workerName = formik.values.workerName;
        const sell = addSellProd(prod, pay, workerName, amount, user as UserFinal, counter)
        batch.set(doc(db, `${'sells'}/${sell.docId}`), sell)
        batch.update(doc(db, `products/${prod.productUid}`), {
            stock: increment(-1)
        })
        await commitBatch(batch);
        toast.success('Venta realizada')
        return sell;
    }


    useEffect(() => {
        if (formik.values.prodId !== '') {
            const p = prods.filter(el => el.productUid === formik.values.prodId)[0];
            setselProd(p)
        } else {
            setselProd(null)
        }
    }, [formik.values.prodId, prods])



    return (
        <div className='p-4'>
            <Form formikVal={formik}>
                <ResCol
                    childrenFirst={
                        <SelectField name='workerName' opts={worOpts} search={false} placeHolder={'Facturación'} />
                    }
                    childrenSecond={
                        <SelectField name='prodId' opts={prodsOpts} search={true} placeHolder={'Buscar producto...'} />
                    }
                />
                {
                    tickets.length > 0 ?
                        <Acc title={'Usar ticket'} id={'Ticket'}>
                            <FormField name={'code'} label={'Escribe el código'} type={'text'} />
                            {
                                formik.values.code !== '' && formik.values.prodId !== '' && formik.values.workerName !== '' &&
                                (
                                    loading ?
                                        <Spinner />
                                        :
                                        <ActionButton
                                            classNames={`${BlockCenter} mt-4`}
                                            text='Usar ticket'
                                            action={processTicket} />

                                )
                            }
                        </Acc>
                        : null
                }
                <div className='mb-5 col-lg-8 fv-row m-auto '>
                    <RadioContainer title={'Selecciona un método de pago'} >
                        <RadioField title='Tarjeta de crédito' name='method' value='Tarjeta de crédito'
                            icon={tarIcon}
                        />
                        <RadioField title='Efectivo' name='method' value='Efectivo'
                            icon={efIcon}
                        />
                    </RadioContainer>
                </div>
                {
                    selProd !== null ?
                        <div className='d-flex justify-content-center'>
                            <SimpleCard
                                childIcon={<i className="fa-solid fa-box text-white"
                                    style={{ fontSize: 35 }}></i>}
                                classNames='w-300px mt-5'
                                title={selProd.productName}
                                text1={`Precio ${showFinal === 0 ? selProd.amount : showFinal.toFixed(2)} € `}
                                text2={`Stock ${selProd.stock.toString()}`} back={'bg-aqua'} />
                        </div>
                        : null
                }
                <div className='d-flex justify-content-end mt-5'>
                    <SubmitBtn loading={loading} text='Pagar' />
                </div>
            </Form>
        </div>
    )
}




