import { UserFinal } from '@models/user/userFinal'
import React from 'react'
interface Props {
  sugg: UserFinal[],
  suggSel: (item: any) => void,
}
export const Sugg = ({ sugg, suggSel }: Props) => {
  return (
    <>
        {sugg.length > 0 ?
          sugg.map((value) => (
            <div className='p-2 text-hover-primary text-left is-pointer ' key={value.uid} onClick={() => suggSel(value)}>
              <p>{`${value.displayName} ${value.phone}`}</p>
            </div>
          ))
          : <p className='text-center mt-5'>Sin resultados, buscando...</p>
        }
    </>
  )
}
