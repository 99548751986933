import { createRoot } from 'react-dom/client'
import { Chart, registerables } from 'chart.js'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider } from './app/modules/auth'
import { Toaster } from 'react-hot-toast';
import * as Sentry from "@sentry/react";
import * as serviceWorker from './serviceRegister';
Chart.register(...registerables)

Sentry.init({
  dsn: "https://9c0bf759c6e345f4a1d09f306fb20475@o4504532194689024.ingest.sentry.io/4505589310554112",
  environment: (process.env.REACT_APP_AUTH_DOMAIN as string).includes('dev') ? 'development' : 'production',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://newadminest160323.web.app/", 'https://reservas.estetical.es/'],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  ignoreErrors: [
  'TypeError: La conexión de red se ha perdido.', 
  'TypeError: cancelled', 
  'TypeError: cancelado']
});

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
      <MetronicI18nProvider>
        <Toaster />
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </MetronicI18nProvider>
  )
}

serviceWorker.unregister()