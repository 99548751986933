import { MultPre, MultPreSer } from "@models/bookings/MultPre"
import { Services } from "@models/services/services";
import React, { useCallback, useEffect, useState } from "react"
import toast from 'react-hot-toast';
import { useAppSelector } from "@redux/store/hooks";
import { selectMachines, selectRestCenter } from "@redux/features/gquerySlice/gquerySlice";
import { FastBook } from "@components/common/wiz/wiztypes/FastBookWizHelper";
import { BigBook } from "@components/common/wiz/wiztypes/BigBookWizHelper";
import { parse } from 'date-fns';
import { selectRedBooks } from "@redux/features/calSlice/calSlice";
import { toMinutes } from "@lib/helpers/dates/toMinutes";
import { retShowing } from "@lib/helpers/dates/retShowing";
import { getMachineBookingsV2 } from "@lib/helpers/machines/getMachineBookingsV2";
import { SellsBonus } from "@models/bono/sellsBonus";
import { Ticket } from "@models/ticket/ticket";
import { Worker } from "@models/user/worker";
import { serviceLimit } from "@models/bono/sessionsPack";
import { validateOneTime } from "@lib/helpers/booking/createTimes/validateOneTime";
import { ServicePair } from "@models/machines/servicePair";

const initCurr = {
    workerName: '',
    workerId: '',
    bono: false,
    bonoName: '',
    bonoId: '',
    via: false,
    ticketUsed: false,
    servicesTicket: []
  }
  
export default function useCommonBooking(
  type: "FAST_BOOK" | 'BIG_BOOK',
  services: Services[],
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  values: FastBook | BigBook,
  setonlyServices: React.Dispatch<React.SetStateAction<string[]>>,
  bonoUser: SellsBonus[],
  ticketsUser: Ticket[],
  setticketsUser: React.Dispatch<React.SetStateAction<Ticket[]>>,
  setbonoUser: React.Dispatch<React.SetStateAction<SellsBonus[]>>,
  workers: Worker[]
) {
  const { restMinutes } = useAppSelector(selectRestCenter)
  const [currMult, setcurrMult] = useState<MultPre>(initCurr)
  const machines = useAppSelector(selectMachines);
  const redBooks = useAppSelector(selectRedBooks)
  const reset = useCallback(() => {
    setonlyServices([]);
    // setFieldValue('currBono', false);
    setFieldValue('currSerId', '');
    setFieldValue('currBonoId', '');
    if (type === 'BIG_BOOK') {
      setFieldValue('workerName', '');
      setFieldValue('workerB', '');
      setFieldValue('doubleWorker', false);
    }
    setcurrMult(initCurr)
  }, [setFieldValue, setonlyServices, type])

  const selectedBono = useCallback((bonoId: string) => {
    const bonoSelected = bonoUser.filter(el => el.id === bonoId)[0]
    if (ticketsUser.some(t => t.code === bonoSelected.name)) {
      const ticketUsed = ticketsUser.filter(el => el.code === bonoSelected.name)[0];
      if (ticketUsed.servicesInc.length !== ticketUsed.servicesUsed.length) {
        const included = ticketUsed.servicesInc.filter((el: ServicePair) => !ticketUsed.servicesUsed.find((a) => a.id === el.id));
        const filt = services.filter(el => included.some((a) => a.id === el.serviceId)).map((value) => value.serviceId);
        if (filt.length === 0) {
          reset()
          return toast.error('No quedan servicios')
        }
        setonlyServices(filt);
        setcurrMult((prev) => {
          return { ...prev, bonoName: bonoSelected.name, bonoId: bonoId, ticketUsed: true }
        })
      } else {
        reset()
        toast.error('No quedan servicios en el ticket');
      }
    } else {
      const selectedBono = bonoUser.filter(b => b.id === bonoId)[0];
      if (selectedBono.servicesLimit === undefined) {
        if (selectedBono.numberSessions === 0) {
          reset()
          return toast.error('No quedan sesiones en el bono')
        } else {
          const def = services.filter(el => selectedBono.services.some((a) => a.id === el.serviceId) && !el.doubleWorker);
          if (def.length === 0) {
            reset()
            return toast.error('Los servicios de este bono ya no están disponibles')
          }
          setonlyServices(def.map((value) => value.serviceId))
        }
      } else {
        if (selectedBono.servicesLimit.every(el => el.sesNumber === 0)) {
          reset()
          return toast.error('No quedan sesiones en el bono')
        } else {
          selectedBono.servicesLimit = selectedBono.servicesLimit.filter(el => el.sesNumber > 0);
          const serNames = selectedBono.servicesLimit.map((val) => val.id);
          const def = services.filter(el => serNames.includes(el.serviceId)).map((value) => value.serviceId);
          if (def.length === 0) { return toast.error('Los servicios de este bono no están disponibles') }
          setonlyServices(def);
        }
      };
      setcurrMult((prev) => {
        return { ...prev, bonoName: bonoSelected.name, bonoId: bonoId, ticketUsed: false, bono: true }
      })
    }
  }, [bonoUser, ticketsUser, services, reset, setonlyServices])

  const add = () => {
    if (type === 'BIG_BOOK') {
      const form = values as BigBook;
      if (form.doubleWorker && values.multArr.length === 1) {
        return toast.error('No puedes reservar más si ya tienes una doble');
      }
    }
    const service = services.filter(s => s.serviceId === values.currSerId)[0];
    if (service.apar) {
      const quest = machines.some(el => el.servicesIds.some(mu => mu.id === service.serviceId));
      if (!quest) {
        return toast.error('Este servicio no está asociado a ninguna máquina');
      } else if (type === 'FAST_BOOK') {
        if (!checkIfAparOk(service)) {
          return toast.error('La máquina no está disponible este día');
        }
        if ( !checkIfAparFull(service) ) {
          return toast.error('La máquina está ocupada');
        }
      }
    }
    if (currMult.bono === true && currMult.ticketUsed === false) {
      const filt = bonoUser.filter(el => el.id === currMult.bonoId)[0];
      const ses = service.numberSessions;
      const sesLeft = filt.servicesLimit === undefined ?
        (filt.numberSessions as number) - ses : filt.servicesLimit.filter(el => el.id === service.serviceId)[0].sesNumber - ses;
      if (sesLeft < 0) {
        return toast.error('No tienes suficientes sesiones para este servicio');
      }
    }
    const finalOb = { ...currMult, service: service } as MultPreSer
    if (type === 'BIG_BOOK') {
      const form = values as BigBook;
      if (values.workerName === 'Cualquier emplead@') {
        finalOb.workerId = 'Cualquier emplead@';
        finalOb.workerName = 'Cualquier emplead@'
      } else {
        const workerA = workers.filter(w => w.name === form.workerName)[0];
        const workerB = workers.filter(w => w.name === form.workerB)[0];
        finalOb.workerName = form.doubleWorker ? workerA.name + ',' + workerB.name : workerA.name
        finalOb.workerId = form.doubleWorker ? workerA.uid + ',' + workerB.uid : workerA.uid
      }
    }
    setFieldValue('multArr', [...values.multArr, finalOb]);
    reset()
    reStateBonoOrTicket(finalOb);
  }

  const trTickets = (index: number, back: boolean, service: Services) => {
    setticketsUser((prev) => {
      const t = [...prev];
      if (back === false) {
        t[index].servicesUsed.push({id: service.serviceId, name: service.serviceName});
        return t;
      } else {
        t[index].servicesUsed = t[index].servicesUsed.filter(el => el.id !== service.serviceId);
        return t;
      }
    });
  }

  const trBonos = (index: number, back: boolean, ses: number, serId: string) => {
    setbonoUser((prev) => {
      const bs = [...prev];
      if (bs[index].servicesLimit === undefined) {
        const curr = (bs[index].numberSessions as number)
        bs[index].numberSessions = back ? curr + ses : curr - ses;
        return bs;
      } else {
        const indexLimit = (bs[index].servicesLimit as serviceLimit[]).findIndex(el => el.id === serId);
        const curr = (bs[index].servicesLimit as serviceLimit[])[indexLimit].sesNumber;
        (bs[index].servicesLimit as serviceLimit[])[indexLimit].sesNumber = back ? curr + ses : curr - ses;
        return bs
      }
    })
  }

  const reStateBonoOrTicket = (res: MultPreSer) => {
    if (res.ticketUsed) {
      const indexTick = ticketsUser.findIndex(t => t.code === res.bonoName);
      trTickets(indexTick, false, res.service)
    } else if (res.bono) {
      const indexBono = bonoUser.findIndex(b => b.id === res.bonoId);
      trBonos(indexBono, false, res.service.numberSessions, res.service.serviceId)
    }
  }
  const deleteItem = (index: number) => {
    reset()
    const check = values.multArr.filter((_, i) => i === index)[0] as MultPreSer; 
    if (check.ticketUsed) {
      const indexTick = ticketsUser.findIndex(te => te.code === check.bonoName);
      trTickets(indexTick, true, check.service)
      toast.success('Servicio de ticket recuperado');
    } else if (check.bono) {
      const indexBono = bonoUser.findIndex(b => b.id === check.bonoId);
      trBonos(indexBono, true, check.service.numberSessions, check.service.serviceId);
      toast.success('Sesiones de bono recuperadas');
    }
    const mults = values.multArr.filter((_, i) => i !== index);
    setFieldValue('multArr', mults)
    toast.success('Cita eliminada');
  }

  const checkIfAparFull = (item: Services) => {
    const date = parse(values.d, "d/M/yyyy, HH:mm", new Date())
    let valid = true;
    const machFilt = machines.filter(el => el.servicesIds.find(mu => mu.id === item.serviceId))[0];
    const stock = machFilt.sesApar;
    let time = { str: 0, end: 0 } as any;
    const start = toMinutes(date.toLocaleString('es-Es', { hour: '2-digit', minute: '2-digit' }))
    if (values.multArr.length > 0) {
      const total = start + values.multArr.reduce((a, b) => +a + + toMinutes(b.service?.duration as string), 0);
      time.str = total;
      time.end = total + toMinutes(item.duration) + restMinutes;
    } else {
      time.str = start
      time.end = time.str + toMinutes(item.duration) + restMinutes;
    }
    const showing = retShowing(date);
    const noCancel = redBooks.filter(el => el.cancelled === undefined);
    let otherWorkerApar = getMachineBookingsV2(machFilt, showing, noCancel);
    otherWorkerApar = otherWorkerApar.sort((a, b) => a.momentIntervalIni - b.momentIntervalIni);
    const mirrStock = []
    if (otherWorkerApar.length !== 0) {
      otherWorkerApar.forEach(b => {
        const valid = validateOneTime(time, [b]);
        if (!valid.valid) {
          mirrStock.push(time);
        }
      });
      if (stock <= mirrStock.length) {
        valid = false;
      }
    }
    return valid
  }

  const checkIfAparOk = (item: Services) => {
    let verFlag = true;
    const date = parse(values.d, "d/M/yyyy, HH:mm", new Date())
    const strDate = date.toLocaleDateString('es-ES', { day: "numeric", month: "numeric", year: "numeric" })
    const machFilt = machines.filter(ma => ma.servicesIds.find(mu => mu.id === item.serviceId))[0];
    if (machFilt.time) {
      if (machFilt.availableDates && !machFilt.availableDates.includes(strDate)) {
        verFlag = false;
      }
    }
    return verFlag;
  }

  useEffect(() => {
    let active = true;
    if (active) {
      setFieldValue('centerRest', restMinutes)
    }
    return () => {
      active = false;
    }
  }, [restMinutes, setFieldValue])

  useEffect(() => {
    let active = true;
    if (values.currBonoId !== '' && active) {
      selectedBono(values.currBonoId)
    }
    return () => {
      active = false;
    }
  }, [values.currBonoId, selectedBono])

  useEffect(() => {
    let active = true;
    if (values.currBono === false && active) {
      reset()
    }
    return () => {
      active = false;
    }
  }, [values.currBono, reset])

  const move = (index: number, side: string) => {
    const mult = values.multArr;
    const to = side === 'up' ? index - 1 : index + 1;
    const moving = mult[index];
    const moved = mult[to];
    mult[index] = moved;
    mult[to] = moving;
    setFieldValue('multArr', mult);
  }
  

  return {
    add,
    deleteItem,
    move
  }

}