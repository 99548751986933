import { toMinutes } from "@lib/helpers/dates/toMinutes";
import { MultPreSer } from "@models/bookings/MultPre"

export const momentEndFast = (preBooks: MultPreSer[], ini: number, centerRest: number) => {
    const first = preBooks[0];
    if (first.service.isExpo === true) {
        let e = ini;
        const arr = first.service.expoArr
        const isFirstCase = arr.length === 2;
        arr.forEach((ex, x) => {
            const lastTempo = x === arr.length - 1;
            const isRest = ex.rest;
            const dur = toMinutes(ex.dur);
            if (lastTempo) {
                if (!isRest) {
                    e = e + dur;
                } 
            } else {
                e = e + dur;
            }
        })
        return preBooks.length > 1 || isFirstCase ? e : e + centerRest
    } else {
        if (preBooks.length > 1) {
            return ini + toMinutes(first.service.duration)

        } else {
            return ini + toMinutes(first.service.duration) + centerRest
        }
    }
}