import { selectCenter, selectSellsCounter } from '@redux/features/gquerySlice/gquerySlice';
import { useAppSelector } from '@redux/store/hooks';
import React, { useEffect, useState } from 'react'
import '@assets/sass/sellsTem.scss'
import { selectorEditId } from '@redux/features/uiSlice/uiSlice';
import { Timestamp, doc, onSnapshot } from 'firebase/firestore';
import { db } from '@lib/firebase';
import { Sell } from '@models/sells/sell';
import { Spinner } from '@components/ui/spinner/Spinner';
import { retShowing } from '@lib/helpers/dates/retShowing';
import { CrossSell } from '@models/sells/crossSell';
export const SellsTemplate = () => {
    const counter = useAppSelector(selectSellsCounter);
    const id = useAppSelector(selectorEditId)
    const [sell, setsell] = useState<Sell | null>(null);
    const [iva, setiva] = useState(0)
    useEffect(() => {
        if (counter) {
            const sub = onSnapshot(doc(db, `sells/${id}`), (snap) => {
                const s = snap.data() as Sell;
                if (s.dateTime instanceof Timestamp) {
                    s.dateTime = s.dateTime.toDate()
                }
                const ivaImport = counter.canary === true ? 1.07 : 1.21;
                const totalNoIva = Math.abs(s.amount / ivaImport);
                const iva = s.amount - totalNoIva;
                setsell(s);
                setiva(iva);
            })
            return () => {
                sub()
            }
        }
    }, [id, counter])

    return (
        <>
            {
                sell ?
                    <div className='body-sell'>
                        <div className='parent-sell'>
                            <section className="wrapper-invoice">
                                <div className="invoice">
                                    <div className="invoice-logo-brand">
                                        <img src={counter?.logo} alt="logo" />
                                    </div>
                                    <div className="invoice-head">
                                        <div className="head client-info">
                                            <p>Nombre de empresa: {sell.busName}</p>
                                            <p>CIF: {sell.cif}</p>
                                            <p>Dirección de la empresa: {sell.dir}</p>
                                            <p>Código postal de la empresa: {sell.postal}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="invoice-information">
                                        <p><b>Factura simplificada nº</b> : {counter?.needsYear ? `${sell.dateTime.getFullYear().toString().substring(2, 4)}/` : ''}{sell.counter.toString()}</p>
                                        <p><b>Fecha de creación </b>: {retShowing(sell.dateTime)}</p>
                                    </div>
                                    <div className="invoice-body">
                                        <table className="table">
                                            {sell.sells.map((simple) => (
                                                <tbody key={simple.uidFind}>
                                                    <tr>
                                                        <td>{simple.name}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="amount">{simple.amount.toFixed(2)} €</td>
                                                        {/* <td>40 €</td> */}
                                                    </tr>
                                                </tbody>
                                            ))}
                                        </table>
                                        <div className="flex-table">
                                            <div className="flex-column">
                                                <table className="table-total">
                                                    <tbody>
                                                        <tr>
                                                            <td>Total EUR</td>
                                                            <td>{sell.amount.toFixed(2)} €</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="flex-table">
                                            <div className="flex-column">
                                                <table className="table-articles">
                                                    <tbody>
                                                        <tr>
                                                            <td>Número de artículos</td>
                                                            <td>{sell.sells.length}</td>
                                                        </tr>
                                                        {
                                                            sell.cross ?
                                                                <>
                                                                    <tr>
                                                                        <td>Efectivo</td>
                                                                        <td>{(sell as CrossSell).efQnt.toFixed(2)} €</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Tarjeta de crédito</td>
                                                                        <td>{(sell as CrossSell).tarQnt.toFixed(2)} €</td>

                                                                    </tr>
                                                                </>
                                                                :
                                                                <tr>
                                                                    <td>{sell.paymentMethod}</td>
                                                                    <td>{sell.amount.toFixed(2)} €</td>
                                                                </tr>

                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="flex-table">
                                            <div className="flex-column">
                                                <table className="table-articles">
                                                    <tbody>
                                                        <tr>
                                                            <td>Resumen IMPUESTOS</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{counter?.canary === true ? '7% IGIC' : '21% IVA'  }</td>
                                                            <td>{(sell.amount - iva).toFixed(2)}</td>
                                                            <td>{iva.toFixed(2)}</td>
                                                            <td>EUR</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invoice-footer">
                                        <p>MUCHAS GRACIAS. HASTA PRONTO</p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    : <Spinner />
            }
        </>
    )
}
