import { ActionButton } from '@components/ui/button/ActionButton'
import { useVerCxt } from '@lib/context/version/VersionCxt'
import { showModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch } from '@redux/store/hooks'
import { toAbsoluteUrl } from '_metronic/helpers'
import { useEffect } from 'react'

export const CheckVer = () => {
    const { currVersion, downloadVersion } = useVerCxt()
    const dispatch = useAppDispatch();
    const reload = () => {
        window.location.reload()
    }
    useEffect(() => {
        if (currVersion < downloadVersion) {
            dispatch(showModal({view: 'NEW_VERSION'}))
        }
    }, [downloadVersion, currVersion, dispatch])
    
    return (
        <>
            {
                currVersion < downloadVersion ?
                <ActionButton isDanger={true} text='Nueva versión disponible' action={reload} />
                :
                <img
                    alt='Logo'
                    className='h-45px logo px-3'
                    src={toAbsoluteUrl('/media/logos/logo-white.png')}
                />

            }        
        </>
    )
}
