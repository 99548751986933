import { TicketForm } from "@components/common/wiz/wiztypes/CreateWizTicket";
import { db } from "@lib/firebase";
import { callCloud } from "@lib/helpers/callCloud";
import { Ticket } from "@models/ticket/ticket";
import { selectCodes, setTicketEnd } from "@redux/features/ticketSlice/ticketSlice";
import { useAppDispatch, useAppSelector } from "@redux/store/hooks";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import * as Sentry from '@sentry/react';
import { UserFinal } from "@models/user/userFinal";

const useCreateTicket = () => {
    const codes = useAppSelector(selectCodes);
    const dispatch = useAppDispatch();
    const createTicket = async (ticketForm: TicketForm) => {
        try {
            const uid = ticketForm.client;
            const userDoc = (await getDoc(doc(db, `users/${uid}`))).data() as UserFinal
            const ticketCol = collection(db, 'tickets');
            const id = doc(ticketCol).id;
            const ticket: Ticket = {
                workerName: ticketForm.workerName,
                code: createCode(),
                amount: Number(ticketForm.amount),
                centerId: ticketForm.centerId,
                bono: ticketForm.bono,
                servicesInc: ticketForm.bono ? ticketForm.services.map((value) => ({name: value.serviceName, id: value.serviceId})) : [],
                servicesUsed: [],
                paid: false,
                userReceives: '',
                userEmail: userDoc.email,
                userEmits: uid,
                userEmReceives: '',
                uid: id,
                date: new Date()
            }
            await setDoc(doc(db, `tickets/${ticket.uid}`), ticket);
            if (userDoc.fakeEmail === false) {
                const relateTicket = callCloud('relateTicket');
                await relateTicket({email: ticket.userEmail, centerId: ticket.centerId, displayName: ticket.centerId, code: ticket.code, amount: ticket.amount});
            }
            dispatch(setTicketEnd({code: ticket.code, amount: ticket.amount, error: false}))
        } catch (error) {
            dispatch(setTicketEnd({code: '', amount: 0, error: true}))
            Sentry.configureScope(scope => scope.setTransactionName('Error createTicket'))
            Sentry.captureException(error, {user: {id: ticketForm.centerId}});

        }
    }
    const createCode = () => {
        let tryId = Math.random().toString(15).substring(2, 5) + Math.random().toString(15).substring(2, 5);
        if(codes.includes(tryId)) {
          while (codes.includes(tryId)) {
            tryId = Math.random().toString(15).substring(2, 5) + Math.random().toString(15).substring(2, 5);
          }
          return tryId
        } else {
          return tryId
        }
    }
    return {
        createTicket
    }
}

export default useCreateTicket