

import { Booking, IdsBooking, InfoBooking, PaymentBooking } from '@models/bookings/booking';
import { addHours, addMinutes } from 'date-fns';
import { doRandomUid } from '../random/doRandomUid';
import { toMinutes } from '../dates/toMinutes';
import { timeToString } from '../dates/timeToString';
import { collection, doc, WriteBatch } from 'firebase/firestore';
import { db } from '@lib/firebase';
import { retStopper } from '../stopper/retStopper';
import { MultiSer } from '@models/bookings/multiser';
const clone = require('clone-deep');
export const addMultipleBook = (incBook: Booking, centerRest: number, addCanary: boolean, batch: WriteBatch) => {
  let start = incBook.momentIntervalIni;
  const totalAmount = incBook.multCounter.reduce((a, b) => +a + +b.servicePrice, 0)
  const randomId = doRandomUid(incBook.centerId);
  incBook.multCounter.forEach(async (mu, i) => {
    const bookCol = collection(db, "bookings");
    const ref = doc(bookCol);
    const isExpo = mu.isExpo === true;
    const dur = toMinutes(mu.serDur);
    let booking = clone(incBook) as Booking;
    const infoBooking: InfoBooking = createInfoBooking(booking, mu);
    booking = { ...booking, ...infoBooking }
    const payInfo: PaymentBooking = createPaymentBooking(booking, mu, totalAmount);
    booking = { ...booking, ...payInfo }
    const idsBook: IdsBooking = createIdsBooking(booking, mu, randomId) 
    booking = {...booking, ...idsBook}
    if (i !== 0) { delete booking.senderUid };
    let today = new Date(incBook.StartTime);
    today.setHours(0, 0, 0, 0);
    const isLast = i === incBook.multCounter.length - 1
    const datesBook = createDatesBooking(start, dur, centerRest, today, addCanary, isLast);
    booking = {...booking, ...datesBook}
    if (isExpo) {
      booking.restTempo = (mu.restTempo as number);
      booking.isExpo = true
      start = booking.momentIntervalEnd + (mu.restTempo as number);
    } else {
      start = start + dur;
    }
    booking.idBook = ref.id;
    const stop = retStopper(booking)
    batch.set(doc(db, 'stopper', booking.idBook), stop)
    batch.set(ref, booking)
  });
}
const createDatesBooking = (
  start: number, 
  dur: number, 
  centerRest: number, 
  startZeroDate: Date,
  addCanary: boolean,
  isLast: boolean,
) => {
  const end = isLast ? start + dur + centerRest : start + dur
  const datesBook = {
    momentIntervalIni: start,
    momentIntervalEnd: end,
    showingHour: timeToString(start),
    StartTime: addMinutes(startZeroDate, start),
    EndTime: addMinutes(startZeroDate, end),
  }
  if (addCanary) {
    datesBook.StartTime = addHours(datesBook.StartTime, 1);
    datesBook.EndTime = addHours(datesBook.EndTime, 1);
  }
  return datesBook;
}

const createInfoBooking = (book: Booking, mu: MultiSer) => {
  const infoBooking: InfoBooking = {
    serviceName: mu.serviceName,
    numberSesSer: mu.numberSesSer,
    comesFromBono: mu.bono ? true : false,
    customerName: book.customerName,
    customerEmail: book.customerEmail,
    customerPhone: book.customerPhone,
    bookCom: '',
    doubleWorker: false,
    multiSer: true,
    multCounter: book.multCounter,
    author: book.author,
  }
  return infoBooking
}

const createPaymentBooking = (book: Booking, mu: MultiSer, am: number) => {
  const payBook: PaymentBooking = {
    paidOnline: false,
    paid: mu.bono || mu.servicePrice === 0 ? true : false,
    paymentMethod: book.paymentMethod,
    amount: am
  }
  return payBook
}

const createIdsBooking = (book: Booking, mu: MultiSer, randomId: string) => {
  const idsBook: IdsBooking = {
    workerId: mu.workerName,
    serviceId: (mu.serviceUid as string),
    bonoSellId: mu.bono ? mu.bonoId : '',
    multCounterId: mu.multCounterId,
    randomDoubleId: '',
    randomMultId: randomId,
    idBook: '',
    customerUid: book.customerUid,
    centerId: book.centerId,
    objDoubleWorker: book.objDoubleWorker

  }
  return idsBook
}


