import { KTCard } from '_metronic/helpers'
import { DateBill } from './DateBill'
import { ShowTotalBill } from './ShowTotalBill'
import { ShowByWorker } from './ShowByWorker'
import { useAppSelector } from '@redux/store/hooks'
import { selectDayArr, selectMonthPdf, selectType } from '@redux/features/billSlice/billSlice'
import { ShowBillTables } from './ShowBillTables'
import { SendPdfBills } from './SendPdfBills'
import { useCallback, useState } from 'react'
import { BoldText } from '@components/ui/text/BoldText'


export const ShowBill = () => {
    const currType = useAppSelector(selectType);
    const dayArr = useAppSelector(selectDayArr);
    const [pdfUrl, setpdfUrl] = useState('')
    const arr = dayArr.split(' - ');
    const month = useAppSelector(selectMonthPdf);

    const putUrl = useCallback((url: string) => {
        setpdfUrl(url)
    }, [])

    return (
        <KTCard className='p-3'>
            {
                pdfUrl !== '' &&
                <a href={pdfUrl} className='mt-5 mb-5'>
                    <BoldText classNames='text-center text-primary fs-2' text={`Pulsa para descargar la facturación de ${month} `} />
                </a>
            }
            {
                arr[0] === arr[1] ? null : <SendPdfBills putUrl={putUrl} />
            }

            <div className='row g-5 g-xl-8'>
                <div className='col-xl-4'>
                    <ShowTotalBill />
                </div>

                <div className='col-xl-4'>
                    <ShowByWorker />
                </div>

                <div className='col-xl-4'>
                    <DateBill />
                </div>
            </div>
            {
                currType === null ?
                    <ShowBillTables />
                    : null
            }

            {/* <ChartCnt /> */}
        </KTCard>
    )
}
