import {Services} from '@models/services/services'
import {SessionsPack, serviceLimit} from '@models/bono/sessionsPack'
import * as Yup from 'yup'

export interface BonoForm {
  nameBonus: string
  active: boolean
  sessionsPack: SessionsPack[]
  name: string
  amountPack: string
  sessionPack: string
  servicesLimit: serviceLimit[]
  img: string
  services: Services[]
  pers: boolean
  currService: string
  limitPack: string,
  description: string
}

const initBonoForm: BonoForm = {
  nameBonus: '',
  active: true,
  sessionsPack: [],
  name: '',
  amountPack: '',
  sessionPack: '',
  servicesLimit: [],
  img: '',
  services: [],
  pers: false,
  currService: '',
  limitPack: '',
  description: ''
}

const BonoFormSchema = [
  Yup.object().shape({
    nameBonus: Yup.string().required('Es necesario un nombre'),
    img: Yup.string().required('Es necesario una imagen'),
    pers: Yup.boolean(),
    description: Yup.string().required('Es necesario una descripción'),
  }),
  Yup.object().shape({
    name: Yup.string(),
    amountPack: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).positive('Número positivo').typeError('Tiene que ser un número'),
    sessionPack: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .positive('Número positivo')
      .typeError('Tiene que ser un número')
      .integer('El número debe ser entero'),
    limitPack: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .positive('Número positivo')
      .typeError('Tiene que ser un número')
      .integer('El número debe ser entero'),
    servicesLimit: Yup.array(),
    sessionsPack: Yup.array(),
  }),
]

export {BonoFormSchema, initBonoForm}