import { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useAppDispatch,  } from '@redux/store/hooks'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { Option } from '@models/opts/Option'
import { Form } from '@components/formik/Form'
import { SelectField } from '@components/formik/SelectField'
import { useAuth } from 'app/modules/auth'
import { ActionButton } from '@components/ui/button/ActionButton'
import { signOut } from 'firebase/auth'
import { db, fireAuth } from '@lib/firebase'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { doc, updateDoc, getDoc, query, collection, where, getDocs } from 'firebase/firestore'
import { Centers } from '@models/centers/center'
import { Admin } from '@models/user/admin'

const initialValues = { centerId: '' }
export const ChangeCenter = () => {
  const dispatch = useAppDispatch()
  const { currentUser } = useAuth()
  // const id = useAppSelector(selectorEditId)
  const [centerIds, setcenterIds] = useState<Option[]>([])
  const [loading, setloading] = useState<boolean>(false)
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      setloading(true)
      await updateDoc(doc(db, `users/${currentUser?.uid}`), {
        centerWorkerName: values.centerId,
      })
      setloading(false)
      dispatch(setCloseModal())
      window.location.reload()
    },
  })

  const out = async () => {
    dispatch(setCloseModal())
    await signOut(fireAuth)
  }

  /* 
    TODO: only valid if less than 10 centers
    // https://stackoverflow.com/questions/61354866/is-there-a-workaround-for-the-firebase-query-in-limit-to-10
  */

  const getCentersAdmin = useCallback(
    async (uid: string) => {
      const user = (await getDoc(doc(db, `users/${uid}`))).data() as Admin;
      const q = query(collection(db, 'centers'), where('centerName', 'in', user.centers))
      const centers = (await getDocs(q)).docs.map((v) => v.data()) as Centers[];
      const opts = centers.map((v) => ({label: v.displayName, value: v.centerName}))
      setcenterIds(opts);
    },
    [],
  )
  

  useEffect(() => {
    if (currentUser && currentUser.role.includes('admin')) {
      getCentersAdmin(currentUser.uid)
    }
  }, [getCentersAdmin])
  

  // useEffect(() => {
  //   const getAllCenters = async () => {
  //     const centersIds = id.split(',')
  //     const centersOpts: Option[] = [];
  //     for (const key of centersIds) {
  //       const center = (await getDoc(doc(db, `centers/${key}`))).data() as Centers;
  //       const opt = {label: center.displayName, value: center.centerName}
  //       centersOpts.push(opt)
  //     }
  //     setcenterIds(centersOpts)
  //   }
  //   getAllCenters()
  // }, [id])

  return (
    <div className='p-4'>
      <Form formikVal={formik}>
        <SelectField opts={centerIds} search={false} name='centerId' placeHolder='Selecciona el centro al que quieras acceder' />
        <div className='d-flex justify-content-between mt-10'>
          <ActionButton action={out} text='Salir de la aplicación' />
          <SubmitBtn loading={loading} />
        </div>
      </Form>
    </div>
  )
}
