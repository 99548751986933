export const genArrSearchUser = (user: any) => {
    try {
        const { displayName, email, phone } = user;
        let resultArray: string[] = [
          displayName.trim(),
          email.trim(), phone.trim()
        ];
        const addSubstrings = (str: string) => {
          for (let i = 1; i <= str.length; i++) {
            resultArray.push(str.slice(0, i));
          }
        }
        addSubstrings(displayName);
        const displayNameArray = displayName.split(' ');
        for (let i = 0; i < displayNameArray.length; i++) {
          addSubstrings(displayNameArray[i]);
        }
        addSubstrings(email);
        addSubstrings(phone);
        resultArray = resultArray.map((v) => v.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
        return resultArray;
        
    } catch (error) {
        return []        
    }
}