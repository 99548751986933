import { CenterCol, JustEnd } from '@assets/sass/bootstrapEx'
import { Form } from '@components/formik/Form'
import FormField from '@components/formik/FormField'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { db } from '@lib/firebase'
import { closeSellsModal, selectEditBill } from '@redux/features/billSlice/billSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { doc, updateDoc } from 'firebase/firestore'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { EditDiffCross } from './EditDiffCross'
import EditAmCross from './EditAmCross'
import { ActionButton } from '@components/ui/button/ActionButton'
import { useBillContext } from '@lib/context/bill/BillCxt'
type EditAmountBillState = 'EDIT_AMOUNT' | 'EDIT_CROSS' | ''
const schema = Yup.object().shape({
  amount: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
    .required('Pon un precio')
    .typeError('Tiene que ser un número')
    .test('more-than-zero', 'El precio debe ser igual a cero o superior', function (value: any) {
      return Number(value) === 0 || Number(value) > 0
    }),
})

const EditAmountBill = () => {
  const { docId, item, } = useAppSelector(selectEditBill);

  const { sells } = useBillContext();
  const [state, setstate] = useState<EditAmountBillState>('EDIT_AMOUNT')
  const dispatch = useAppDispatch();
  const [needsDif, setneedsDif] = useState(false)
  const isCross = item && item.cross === true;
  useEffect(() => {
    if (item?.cross === true) {
      setstate('')
    }

  }, [item])


  const changeAmount = useFormik({
    initialValues: { amount: `${item!.amount.toFixed(2)}` },
    validationSchema: schema,
    onSubmit: async (values) => {
      const price = Number(values.amount);
      if (isCross) {
        if (price !== item!.amount) {
          setneedsDif(true)
          return
        } else {
          return toast.error('No se ha cambiado el precio')
        }
      }
      const col = item!.col ? item!.col : 'sells';
      const parent = sells.filter(sell => sell.docId === docId)[0];
      const newSells = parent.sells.map((v) => 
          ({...v, amount: v.uidFind === item!.uidFind ? price : v.amount}));
      const newAmount = newSells.reduce((a, b) => +a + +b.amount, 0);
      await updateDoc(doc(db, `${col}/${docId}`), {
        sells: newSells,
        amount: newAmount
      })
      toast.success('Cambiado');
      dispatch(closeSellsModal())
    },
  })

  if (!item) {
    return null;
  }

  return (
    <div className='p-4'>
      {
        state === '' ?
          <div className='p-4 d-flex justify-content-between'>
            <ActionButton text={'Editar la cantidad total'} action={() => setstate('EDIT_AMOUNT')} />
            <ActionButton text={'Editar tarjeta y efectivo'} action={() => setstate('EDIT_CROSS')} />
          </div>
          : null
      }
      {
        state === 'EDIT_CROSS' ?
          <div>
            <EditAmCross />
            <div className={`${JustEnd}`}>
              <ActionButton text={'Volver'} action={() => setstate('')} />
            </div>
          </div>
          : null
      }

      {
        state === 'EDIT_AMOUNT' ?
          <div>
            {
              needsDif ?
                <EditDiffCross newPrice={changeAmount.values.amount} item={item} />
                :
                <Form formikVal={changeAmount}>
                  <FormField name={'amount'} label={`Estás editando el precio de ${item.name}`} type={'text'} />
                  <div className={`${CenterCol} mt-5`}>
                    <SubmitBtn loading={false} />
                  </div>
                </Form>

            }
            {
              isCross ?
                <div className={`${JustEnd}`}>
                  <ActionButton text={'Volver'} action={() => setstate('')} />
                </div> : null
            }
          </div>
          : null
      }

    </div>
  )
}

export default EditAmountBill