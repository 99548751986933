import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { Form } from '@components/formik/Form'
import FormField from '@components/formik/FormField'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import toast from 'react-hot-toast'
import { selectBonoCh } from '@redux/features/clientSlice/clientSlice'
import { SellsBonus } from '@models/bono/sellsBonus'
import { Col } from '@assets/sass/bootstrapEx'
import { Acc } from '@components/ui/accordion/Acc'
import { ChangePers } from './ChangePers'
import { Ticket } from '@models/ticket/ticket'
import { BonoTickSes } from './BonoTickSes'

const initNormal = { newSes: '' }

const schemaNormal = Yup.object().shape({
    newSes: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).typeError('Tiene que ser un número').integer('El número debe ser entero')
})
export const ModifySes = () => {
    const dispatch = useAppDispatch()
    const bonoChange = useAppSelector(selectBonoCh) as SellsBonus;
    const [ticketRel, setticketRel] = useState<Ticket | null>(null)
    const [loading, setloading] = useState<boolean>(false)

    const formik = useFormik({
        initialValues: initNormal,
        validationSchema: schemaNormal,
        onSubmit: async (values) => {
            setloading(true)
            const { newSes } = values;
            const n = Number(newSes)
            await updateDoc(doc(db, `sellsBonus/${bonoChange.id}`), {
                numberSessions: n
            })
            toast.success('Hecho')
            setloading(false)
            dispatch(setCloseModal())
        },
    })

    useEffect(() => {
      let active = true;
      if (active && bonoChange.hasTicket === true) {
        const getTicket = async () => {
            const ticket = (await getDocs(query(collection(db, 'tickets'), where('code', '==', bonoChange.name))))
            .docs.map(value => value.data())[0] as Ticket;
            setticketRel(ticket)
        }
        getTicket();
      }
      return () => {
        active = false;
      }
    }, [bonoChange])
    
    return (
        <>
            {
                bonoChange.servicesLimit ?
                    <div className={`${Col} p-4`}>
                        {
                            bonoChange.servicesLimit.map((value, index) => (
                                <ChangePers key={index} index={index} bono={bonoChange} servicesLimit={value} />
                            ))
                        }
                    </div>
                    :
                    <div className={`${Col} p-4`}>
                        {
                            bonoChange && bonoChange.hasTicket === true && ticketRel ? 
                            <BonoTickSes ticket={ticketRel} />
                            :
                            <Acc title={`Modificar sesiones ${bonoChange.numberSessions}`} id='Normal'>
                                <Form formikVal={formik}>
                                    <div className='w-300px d-block m-auto'>
                                        <FormField name={'newSes'} label={'Introduce las nuevas sesiones'} type={'text'} />
                                        <div className='d-flex justify-content-center'>
                                            <SubmitBtn loading={loading} />
                                        </div>
                                    </div>
                                </Form>
                            </Acc>
                        }
                    </div>
            }

        </>
    )
}
