import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Form } from '@components/formik/Form'
import { useAuth } from 'app/modules/auth'
import useServicesBooking from '@lib/hooks/calendar/book/useServicesBooking'
import { Services } from '@models/services/services'
import { PhotoItem } from '@components/ui/item/PhotoItem'
import { ActionButton } from '@components/ui/button/ActionButton'
import toast from 'react-hot-toast'
import { InlineSelect } from '@components/formik/InlineSelect'
import { InlineInput } from '@components/formik/InlineInput'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { Machine } from '@models/machines/machine'
import { collection, doc, setDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
const initialValues = { name: '', sesApar: '', currService: '', services: [], }
const schema = Yup.object().shape({
    name: Yup.string().required('Es necesario un nombre'),
    sesApar: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).required('Pon las sesiones').positive('Número positivo').typeError('Tiene que ser un número').integer('Sin decimales'),
    services: Yup.array().min(1, 'Elige por lo menos un servicio')
})

export const CreateMach = () => {
    const { optsServices, services } = useServicesBooking([])
    const { centerId } = useAuth()
    const [loading, setloading] = useState<boolean>(false)
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            const machCol = collection(db, 'machine');
            const id = doc(machCol).id;
            setloading(true)
            const m: Machine = {
                docId: id,
                name: values.name,
                time: false,
                sesApar: Number(values.sesApar),
                servicesIds: values.services.map((s: Services) => ({id: s.serviceId, name: s.serviceName})),
                centerId: centerId,
            }
            await setDoc(doc(db, `machine/${m.docId}`), m)
            toast.success('Máquina creada')
            setloading(false)
            setValues({ ...initialValues })
            resetForm()
        },
    })
    const deleteItem = (index: number) => {
        const newData = formik.values.services.filter((_, i) => i !== index)
        formik.setFieldValue('services', newData)
    }

    const add = () => {
        const sel = services.filter(s => s.serviceId === formik.values.currService)[0];
        if ((formik.values.services as Services[]).some(el => el.serviceId === sel.serviceId)) {
            toast.error('Servicio ya incluído')
        } else {
            const newData = (formik.values.services as Services[]).concat(sel)
            formik.setFieldValue('services', newData)
        }
        formik.setFieldValue('currService', '')
    }
    return (
        <div className='p-4'>
            <Form formikVal={formik}>
                <InlineInput name={'name'} label={'Nombre de la máquina'} />
                <InlineInput name={'sesApar'} label={'Stock de la máquina'} />

                <InlineSelect name='currService' opts={optsServices} search={true} label={'Elige los servicios'} />
                {
                    formik.values.currService !== '' &&
                    <ActionButton text={'Añadir'} action={add} classNames='mt-3' />
                }
                {
                    formik.values.services.map((ser: Services, index: number) => (
                        <div key={ser.serviceId} className='me-5 mt-4'>
                            <PhotoItem
                                index={index}
                                img={ser?.img as string}
                                title={ser?.serviceName as string}
                                subText={ser?.cat}
                                deleteFun={() => deleteItem(index)} whatever={undefined} />

                        </div>
                    ))
                }
                <div className={`${JustEnd} mt-3`}>
                    <SubmitBtn loading={loading} />
                </div>
            </Form>
        </div>
    )
}
