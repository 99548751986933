import { format } from 'date-fns';
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { SelectField } from './SelectField';
import { Option } from '@models/opts/Option'
import es from 'date-fns/locale/es'
type Props = {
    className?: string
}
export const MonthPicker = ({className}: Props) => {
    const { values, setFieldValue } = useFormikContext<any>();
    const [months, setmonths] = useState<Option[]>([])
    const moveYear = (dir: string) => {
        const currYear = values.year
        if (dir === 'LEFT') {
            setFieldValue('year', currYear - 1)
        } else {
            setFieldValue('year', currYear + 1)
        }
    }
    useEffect(() => {
        const monthsInSpanish = Array.from({ length: 12 }, (_, index) => {
            const date = new Date(2000, index, 1);
            return format(date, 'MMMM', { locale: es });
        });
        setmonths(monthsInSpanish.map((v) => ({ label: v, value: v })))
    }, [])
    return (
        <div className={className ? className : ''}>
            <div className='d-flex flex-row align-items-center justify-content-center mb-5'>
                <i className="fa-solid fa-arrow-left text-primary is-pointer fs-3" onClick={() => { moveYear('LEFT') }}></i>
                <p className='mb-0 me-3 ms-3 fs-3'>{values.year}</p>
                <i className="fa-solid fa-arrow-right text-primary is-pointer fs-3" onClick={() => { moveYear('RIGHT') }}></i>
            </div>
            <SelectField search={true} opts={months} name={'month'} placeHolder={'Selecciona un mes'} />
        </div>
    )
}
