import { MbscEventcalendarView } from '@mobiscroll/react'
import {SellsBonus} from '@models/bono/sellsBonus'
import { BookingWithStringTime } from '@models/bookings/booking'
import { initSchedule } from '@models/calendar/calendarTypes'
import {Ticket} from '@models/ticket/ticket'
import { TurnAuto } from '@models/turns/turn-auto'
import {Variats} from '@models/variats/variats-int'
import {RootState} from '@redux/store/store'
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {cloneDeep} from 'lodash'
export interface NeedEmInfo {
  senderUid: string
  currEm: string
  userUid: string
  centerId: string
  idBook: string
}
interface CalState {
  needEmInfo: NeedEmInfo
  bonos: SellsBonus[]
  tickets: Ticket[]
  variatsUnpaid: Variats[],
  bookStr: BookingWithStringTime[],
  bookDebt: number,
  counterBooking: number,
  turnsAuto: TurnAuto[],
  bookOpen: boolean,
  initSched: MbscEventcalendarView | null
}

const initialState: CalState = {
  needEmInfo: {senderUid: '', currEm: '', centerId: '', userUid: '', idBook: ''},
  bonos: [],
  tickets: [],
  variatsUnpaid: [],
  bookStr: [],
  counterBooking: 0,
  bookDebt: 0,
  turnsAuto: [],
  bookOpen: false,
  initSched: null
}

export const calSlice = createSlice({
  name: 'cal',
  initialState,
  reducers: {
    setBook: (state, action: PayloadAction<NeedEmInfo>) => {
      const payload = action.payload
      state.needEmInfo = payload
    },
    setBonosCal: (state, action: PayloadAction<SellsBonus[]>) => {
      state.bonos = action.payload
    },
    setTicketsCal: (state, action: PayloadAction<Ticket[]>) => {
      state.tickets = action.payload
    },
    setVariats: (state, action: PayloadAction<Variats[]>) => {
      const payload = action.payload
      state.variatsUnpaid = payload
    },
    setBookings: (state, action: PayloadAction<BookingWithStringTime[]>) => {
      const payload = action.payload
      state.bookStr = payload;
    },
    setNewCounterBook: (state) => {
      const curr = state.counterBooking;
      state.counterBooking = curr + 1;
    },
    setTurnsAuto: (state, action: PayloadAction<TurnAuto[]>) => {
      const payload = action.payload
      state.turnsAuto = payload;
    },
    setDebtBook: (state, action: PayloadAction<number>) => {
      const payload = action.payload
      state.bookDebt = payload;
    },
    setBookOpen: (state, action: PayloadAction<boolean>) => {
      const payload = action.payload;
      state.bookOpen = payload;
    },
    setInitSched: (state, action: PayloadAction<boolean>) => {
      const isDom = action.payload
      const sched = cloneDeep(initSchedule);
      sched.schedule!.endDay = isDom ? 0 : 6;
      state.initSched = sched;
    },
  },
})
export const selectOpen = (state: RootState) => state.calSlice.bookOpen
export const selectRedBooks = (state: RootState) => state.calSlice.bookStr
export const selectNeed = (state: RootState) => state.calSlice.needEmInfo
export const selectVariats = (state: RootState) => state.calSlice.variatsUnpaid
export const selectCounter = (state: RootState) => state.calSlice.counterBooking
export const selectTurnsAuto = (state: RootState) => state.calSlice.turnsAuto
export const selectDebtBook = (state: RootState) => state.calSlice.bookDebt
export const selectSched = (state: RootState) => state.calSlice.initSched
export const selectBonoTicket = createSelector(
  (state: RootState) => state.calSlice,
  (cal) => {
    return {
      bonos: cal.bonos,
      tickets: cal.tickets,
    }
  }
)

export const {
  setBook, 
  setBonosCal, 
  setTicketsCal, 
  setVariats, 
  setBookings,
  setNewCounterBook,
  setTurnsAuto,
  setDebtBook,
  setBookOpen,
  setInitSched
} = calSlice.actions
export default calSlice.reducer
