import { Bonus } from "@models/bono/bonus";
import { SessionsPack } from "@models/bono/sessionsPack";
import * as Yup from 'yup'
export type typePayBono = 'Simple' | 'Frac'
export interface BonusSell {
    workerName: string,
    code: string,
    bonoId: string,
    method: string,
    discount: string,
    newPrice: string,
    indexPack: null | string,
    selBonus: Bonus | null
    packSel: SessionsPack | null,
    simple: typePayBono,
    firstPayment: string,
    finalPrice: number
}

const BonusSellSchemas = [
    Yup.object().shape({
        bonoId: Yup.string().required('Selecciona el producto'),
        workerName: Yup.string().required('Selecciona el emplead@'),
        indexPack: Yup.mixed().required('Selecciona un pack')
    }),
    Yup.object().shape({
        simple: Yup.string(),
        finalPrice: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)),
        method: Yup.string().required('Selecciona el método de pago'),
        discount: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).positive('Número positivo').typeError('Tiene que ser un número').integer('El número debe ser entero'),
        newPrice: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).typeError('Tiene que ser un número'),
        firstPayment: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).when('simple', {
            is: (simple: typePayBono) => simple === 'Frac',
            then: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).typeError('Tiene que ser un número').required('Falta el primer pago')
            .test('is-less-than-final-price', 'El primer pago no puede ser mayor al precio final', function (value: any) {
                const finalPrice = this.resolve(Yup.ref('finalPrice'));
                return Number(value) < (finalPrice as number);
              }),
            otherwise: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)),
            
        })
    })
]

const initBonusSell: BonusSell = {
    workerName: '',
    code: '',
    bonoId: '',
    method: '',
    discount: '',
    newPrice: '',
    simple: 'Simple',
    indexPack: null,
    selBonus: null,
    packSel: null,
    firstPayment: '',
    finalPrice: 0
}

export {initBonusSell, BonusSellSchemas}