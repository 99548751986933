import { Badge } from '@components/ui/badge/Badge';
import { BarItem } from '@components/ui/item/BarItem';
import { TDAny } from '@components/ui/table/TDAny';
import { TDText } from '@components/ui/table/TDText';
import { TRPar } from '@components/ui/table/TRPar';
import { Booking } from '@models/bookings/booking';
interface Props {
  dataTable: Booking[]
}
export const ClientBooks = ({dataTable}: Props) => {
  return (
    <>    
      {
            dataTable.map((value) => (
              <TRPar key={value.idBook}>
                <TDAny>
                  <BarItem isTable={true} title={value.serviceName ? value.serviceName : (value.Subject as string)} desc={''} back={'bg-aqua'} />
                </TDAny>
                <TDText text={value.showingDate} />
                <TDAny >
                  <Badge isTable={true} text={value.showingHour} type={'SUCC'} />
                </TDAny>
                <TDText text={value.workerId} />
                <TDText text={`${value.amount.toFixed(2)} €`} />
                <TDText text={value.cancelled === undefined ? 'Activa' : 'Cancelada'} />
              </TRPar>
            ))
          }
    </>
  )
}
