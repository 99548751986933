import { CenterCol } from '@assets/sass/bootstrapEx'
import { ActionButton } from '@components/ui/button/ActionButton'
import React from 'react'

export const ModalVer = () => {
    const reload = () => {
        window.location.reload()
    }
    return (
        <div className={`${CenterCol} w-100 p-4 `}>
                <ActionButton isDanger={true} text='Nueva versión disponible' action={reload} />
        </div>
    )
}
