import { Form } from '@components/formik/Form'
import FormField from '@components/formik/FormField'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { db } from '@lib/firebase'
import { genArrSearchUser } from '@lib/helpers/user/genArrSearchUser'
import { selectUid, selectUser } from '@redux/features/clientSlice/clientSlice'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { doc, updateDoc } from 'firebase/firestore'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
const initialValues = {
    displayName: ''
}
const schema = Yup.object().shape({
    displayName: Yup.string().required('Es necesario un teléfono')
})
export const ChangeName = () => {
    const [loading, setloading] = useState<boolean>(false)
    const { uid } = useAppSelector(selectUid);
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch()
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            if (user) {
                const dataSearch = {displayName: values.displayName, phone: user.phone, email: user.email};
                const newSearchArr = genArrSearchUser(dataSearch);
                setloading(true)
                await updateDoc(doc(db, `users/${uid}`), {
                    displayName: values.displayName,
                    search: newSearchArr
                })
                setloading(false)
                toast.success('Cambiado')
                dispatch(setCloseModal())
            } else {
                toast.error('Error')
            }
        },
    })
    return (
        <Form formikVal={formik}>
            <FormField name={'displayName'} label={'Cambiar nombre'} type={'text'} />
            <div className='d-flex justify-content-center mt-5'>
                <SubmitBtn loading={loading} text='Enviar' />
            </div>
        </Form>
    )
}
