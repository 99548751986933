import { Form } from '@components/formik/Form'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import Switch from '@components/formik/Switch'
import { ActionButton } from '@components/ui/button/ActionButton'
import { db } from '@lib/firebase'
import { Admin } from '@models/user/admin'
import { doc, updateDoc } from 'firebase/firestore'
import { useFormik } from 'formik'
import React from 'react'
import toast from 'react-hot-toast'
interface Props {
    admin: Admin,
}
export const ChangeNots = ({ admin }: Props) => {
    const initialValues = {
        receiveEmail: admin.receiveEmail,
        receiveEmailApp: admin.receiveEmailApp,
    }
    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setValues, resetForm }) => {
            await updateDoc(doc(db, `users/${admin.uid}`), {
                receiveEmail: values.receiveEmail,
                receiveEmailApp: values.receiveEmailApp,
            })
            toast.success('Cambiado')
            setValues({
                receiveEmail: values.receiveEmail,
                receiveEmailApp: values.receiveEmailApp,
            });
            resetForm()
        },
    })
    return (
        <div className='d-flex flex-column'>
            <Form formikVal={formik}>
                <div className='d-flex flex-column justify-content-center align-items-center mb-5'>
                    <Switch defvalue={initialValues.receiveEmail} nameField='receiveEmail' holder='Notificaciones nuevas citas admin' />
                    <Switch defvalue={initialValues.receiveEmailApp} nameField='receiveEmailApp' holder='Notificaciones nuevas citas app' />
                </div>
                <div className='mt-2 mb-2 d-flex justify-content-center'>
                    <SubmitBtn loading={false} />
                </div>
            </Form>
        </div>
    )
}
